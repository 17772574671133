import React from "react";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

export default function MatchCardSmall({ match }) {
  const pointsA = match?.teamA?.points;
  const pointsB = match?.teamB?.points;
  const winner = pointsA === pointsB ? null : pointsA > pointsB ? "a" : "b";
  const dateAndTime = new Date(match?.dateAndTime);
  const date = dateAndTime.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
  });
  const time = dateAndTime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
  return (
    <div className="shadow-sm bg-white rounded-md h-[11.5rem] w-[16rem] flex flex-col border">
      <div className="h-[20%] px-2 py-1 flex  items-end">
        <div className="flex justify-between items-center gap-2 w-full">
          <p className="text-zinc-500 text-[12px]">{date}</p>
          <p className="text-zinc-500 text-[12px]">{time}</p>
        </div>
      </div>
      <div className="border-y h-[60%] flex flex-col gap-2 p-3 text-zinc-700">
        {/* Team A */}
        <div className="flex justify-between items-center gap-2">
          <div className="flex gap-3 items-center">
            <ProfilePhoto
              name={match?.teamA?.name ?? "Team A"}
              color="black"
              restClass="w-9 h-9 rounded-full"
            />
            <p
              className={`text-lg font-bold uppercase  ${
                winner === "a" && "text-app-orange"
              }`}
            >
              {match?.teamA?.name.substring(0, 3)}
            </p>
          </div>

          <p
            className={`text-xl font-bold ${
              winner === "a" && "text-app-orange"
            }`}
          >
            {match?.teamA?.points}
          </p>
        </div>
        {/* Team B */}
        <div className="flex justify-between items-center gap-2">
          <div className="flex gap-3 items-center">
            <ProfilePhoto
              name={match?.teamB?.name ?? "Team B"}
              color="black"
              restClass="w-9 h-9 rounded-full"
            />
            <p
              className={`text-lg font-bold uppercase  ${
                winner === "b" && "text-app-orange"
              }`}
            >
              {match?.teamB?.name.substring(0, 3)}
            </p>
          </div>
          <p
            className={`text-xl font-bold ${
              winner === "b" && "text-app-orange"
            }`}
          >
            {match?.teamB?.points}
          </p>
        </div>
      </div>
      <div className="h-[30%] text-[11px]">
        <div className="border-b px-2 uppercase font-medium text-zinc-400 py-[2px] tracking-wide">
          {match?.status}
        </div>
        <p className="p-2 text-black italic font-semibold">{match?.venue}</p>
      </div>
    </div>
  );
}
