import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";

import { LOGIN_MODAL } from "../extras/constants";

import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/auth";
import { showModal } from "../redux/actions/modal";
const Navbar = ({ showModal, logoutUser, auth }) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNewMatch = () => {
    let url = location.pathname.split("/");
    let gameType = url[2];
    let id = url[3];
    if (
      url.includes("tournaments") &&
      !url.includes("allothertournaments") &&
      !url.includes("createTournament")
    ) {
      navigate(`/tournaments/${gameType}/${id}/createMatch`);
    } else {
      navigate(`/matches/${gameType}/createMatch`);
    }
  };
  return (
    <header className="z-50">
      <nav className="shadow-md z-50 bg-white px-2">
        <div className="max-w-screen-xl mx-auto py-2">
          <div className="flex justify-between w-full">
            <div className="flex items-center gap-8">
              <div className="flex items-center">
                <Link to="/" className="flex items-center">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="hidden md:flex items-center justify-center gap-12">
              <Link
                to="/"
                className={`py-4 px-2 ${
                  location.pathname === "/"
                    ? " text-app-orange"
                    : " text-black hover:text-[#F2994A] transition duration-300"
                } font-bold text-sm font-mont`}
              >
                Home
              </Link>

              {/* <Link
                  to="/tournaments"
                  className={`py-4 px-2 ${
                    location.pathname === "/tournaments"
                      ? " text-app-orange"
                      : " text-black hover:text-[#F2994A] transition duration-300"
                  } font-bold text-sm `font-mont}
                >
                  Tournaments
                </Link>

                {/* <Link
                  to="/myGames"
                  className={`py-4 px-2 ${location.pathname === "/myGames"
                      ? " text-app-orange"
                      : " text-black hover:text-[#F2994A] transition duration-300"
                    } font-semibold font-mont `}
                >
                  My Games
                </Link> */}

              <Link
                to="/matches/basketball/s4a"
                className={`py-4 px-2 ${
                  location.pathname === "/matches" ||
                  location.pathname === "/matches/:gameType" ||
                  location.pathname === `/newmatch` ||
                  location.pathname.includes("/matches")
                    ? " text-app-orange"
                    : " text-black hover:text-[#F2994A] transition duration-300"
                } font-bold text-sm font-mont`}
              >
                Matches
              </Link>

              <Link
                to="/tournaments/basketball/allothertournaments"
                className={`py-4 px-2 ${
                  location.pathname.includes("/tournaments")
                    ? " text-app-orange"
                    : " text-black hover:text-[#F2994A] transition duration-300"
                } font-bold text-sm font-mont`}
              >
                Tournaments
              </Link>

              <Link
                to="/profile"
                className={`py-4 px-2 ${
                  location.pathname === "/profile"
                    ? " text-app-orange"
                    : " text-black hover:text-[#F2994A] transition duration-300"
                } font-bold text-sm font-mont`}
              >
                Profile
              </Link>
            </div>
            {auth.user ? (
              <div className="hidden md:flex  items-center space-x-3">
                <div
                  onClick={() => navigate("/profile")}
                  className="py-2 cursor-pointer px-2 font-medium text-white bg-app-orange rounded hover:bg-app-secondary transition duration-300"
                >
                  <div className="px-2 rounded-sm text-black text-center">
                    {auth.user?.name}
                  </div>
                </div>

                <button
                  onClick={handleNewMatch}
                  className="py-2 mr-2 px-2 font-medium text-white  rounded bg-black transition duration-300"
                >
                  New Match
                </button>
                <button
                  onClick={() => logoutUser()}
                  className="py-2 mr-2 px-2 font-medium text-gray-500 rounded hover:bg-app-secondary hover:text-white transition duration-300"
                >
                  Logout
                </button>
              </div>
            ) : (
              <div className="hidden md:flex items-center space-x-3">
                <button
                  onClick={() =>
                    showModal({
                      modalType: LOGIN_MODAL,
                    })
                  }
                  className="px-6 py-3 uppercase font-medium text-white hover:bg-app-secondary bg-black rounded-[15px] text-xs font-opsans transition duration-300"
                >
                  Sign In
                </button>
              </div>
            )}
            <div className="md:hidden flex items-center">
              <button
                className="outline-none mobile-menu-button"
                onClick={() => setMobileMenu(!mobileMenu)}
              >
                <svg
                  className=" w-6 h-6 text-gray-500  "
                  x-show="!showMenu"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${!mobileMenu && "hidden"} md:hidden mobile-menu z-50`}
        >
          <ul className="">
            <li className="active">
              <Link
                to="/"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                Home
              </Link>
            </li>
            {/* <li className="active">
              <Link
                to="/tournaments"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                Tournaments
              </Link>
            </li> */}
            {/* <li className="active">
              <Link
                to="/myGames"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                My Games
              </Link>
            </li> */}
            <li>
              <Link
                to="/matches/basketball/s4a"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                Matches
              </Link>
            </li>
            <li>
              <Link
                to="/tournaments/basketball/allothertournaments"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm  px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                Tournament
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                onClick={() => setMobileMenu(!mobileMenu)}
                className=" block text-sm px-5 py-4 hover:bg-app-secondary transition duration-300"
              >
                profile
              </Link>
            </li>

            {auth.user ? (
              <>
                <li>
                  <button
                    onClick={() => logoutUser()}
                    className="block text-sm text-left px-5 py-4 hover:bg-app-secondary transition duration-300 w-full"
                  >
                    Logout
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleNewMatch()}
                    className="block text-sm text-left px-5 py-4 hover:bg-black transition duration-300 w-full"
                  >
                    New Match
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <button
                    onClick={() =>
                      showModal({
                        modalType: LOGIN_MODAL,
                      })
                    }
                    className="px-4 py-3 uppercase font-medium text-white hover:bg-app-secondary bg-black rounded-full text-sm font-opsans transition duration-300"
                  >
                    Sign In
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
