import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import DatePicker from "../../components/DatePicker";
import Card from "../../components/Tournament/Card";

export default function AllTournaments() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTournaments = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get("/tournament/all");
      if (res.status === 200) setData(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTournaments();
  }, []);

  const [form, setForm] = React.useState({
    date: new Date().toISOString().split("T")[0],
  });
  return (
    <div className="w-full py-10 flex flex-col gap-10">
      <div className="max-w-screen-xl w-full mx-auto flex flex-col gap-5 md:px-0 px-3">
        <DatePicker form={form} setForm={setForm} />
        <div className="flex flex-col gap-5">
          {loading && <h1>Loading...</h1>}
          {data &&
            data?.data &&
            !loading &&
            data?.data.map((item) => {
              return <Card data={item} key={item?._id} />;
            })}
        </div>
      </div>
    </div>
  );
}
