import React from "react";
import MatchesTeamCard from "../MatchesPage/MatchesTeamCard";

export default function Games({ matches }) {
  return matches && matches.length > 0 ? (
    <div className="font-mont flex flex-col gap-12">
      <div className="flex flex-col gap-9">
        <h1 className="font-bold text-lg">GAMES</h1>
        {matches.map((match) => (
          <MatchesTeamCard key={match._id} data={match} />
        ))}
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-center h-96">
      <p className="text-lg font-bold">No Games</p>
    </div>
  );
}
