import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AnimatePresence, motion } from "framer-motion";

export default function Selector({ title, options, filter, setFilter }) {
  const [open, setOpen] = useState(false);

  const handleOptionClick = (option) => {
    setFilter(option);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="w-52 font-semibold uppercase relative">
      <button
        onClick={handleOpen}
        className={`${filter === options[0] ? "bg-white" : "bg-app-orange"} rounded-xl text-sm border w-full flex items-center justify-between p-2 cursor-pointer uppercase font-bold"`}
      >
        {filter}
        <BiChevronDown
          size={20}
          className={`${
            open && "rotate-180"
          } transition-all duration-300 transform`}
        />
      </button>
      <AnimatePresence>
        {open && (
          <motion.ul
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.1 }}
            className="bg-white mt-1 overflow-y-auto max-h-60 shadow-lg rounded-xl absolute w-full z-10"
          >
            {options.map((option, index) => {
              return (
                <li
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  className={`p-3 cursor-pointer px-4 text-[13px] tracking-wide hover:bg-gray-100
                ${option === filter ? "text-app-orange" : ""}
              `}
                >
                  {option}
                </li>
              );
            })}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}
