export const nodes = [
  {
    id: "1",
    data: { label: "Team 1" },
    position: { x: 0, y: 50 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "2",
    data: { label: "Team 2" },
    position: { x: 0, y: 150 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "3",
    data: { label: "Team 3" },
    position: { x: 0, y: 250 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "4",
    data: { label: "Team 4" },
    position: { x: 0, y: 350 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "5",
    data: { label: "Team 5" },
    position: { x: 200, y: 100 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "6",
    data: { label: "Team 6" },
    position: { x: 200, y: 300 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "7",
    data: { label: "Team 7" },
    position: { x: 400, y: 200 },
    sourcePosition: "right",
    targetPosition: "left",
  },

  // Right side
  {
    id: "8",
    data: { label: "Team 8" },
    position: { x: 600, y: 200 },
    sourcePosition: "left",
    targetPosition: "right",
  },
  {
    id: "9",
    data: { label: "Team 9" },
    position: { x: 800, y: 100 },
    sourcePosition: "left",
    targetPosition: "right",
  },
  {
    id: "10",
    data: { label: "Team 10" },
    position: { x: 800, y: 300 },
    sourcePosition: "left",
    targetPosition: "right",
  },
  {
    id: "11",
    data: { label: "Team 11" },
    position: { x: 1000, y: 50 },
    sourcePosition: "left",
    targetPosition: "right",
  },
  {
    id: "12",
    data: { label: "Team 12" },
    position: { x: 1000, y: 150 },
    sourcePosition: "left",
    targetPosition: "right",
  },
  {
    id: "13",
    data: { label: "Team 13" },
    position: { x: 1000, y: 250 },
    sourcePosition: "left",
    targetPosition: "right",
  },
  {
    id: "14",
    data: { label: "Team 14" },
    position: { x: 1000, y: 350 },
    sourcePosition: "left",
    targetPosition: "right",
  },
];
