import React from "react";
import PlayerCard from "../../components/profile/PlayerCard";
import { Link } from "react-router-dom";

export default function First({ players }) {
  return players && players.length > 0 ? (
    <div className="font-mont flex flex-col gap-12">
      <div className="flex flex-col gap-9">
        <h1 className="font-bold text-lg">PLAYERS</h1>
        <div className="border border-border-gray rounded-2xl flex flex-col gap-5">
          <div className="w-full grid md:grid-cols-2 gap-8 p-5">
            {players.map((player) => (
              <Link to={`/profile/${player._id}`} key={player._id}>
                <PlayerCard player={player} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-center h-96">
      <p className="text-lg font-bold">No Players</p>
    </div>
  );
}
