import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ArrowBack } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { ERROR, PLAYER_EXISTS_MODAL } from "../../extras/constants";
import useGetMatch from "../../hooks/api/useGetMatch";
import { setPlayerDetail } from "../../redux/actions/auth";
import { showModal } from "../../redux/actions/modal";
import { addToast } from "../../redux/actions/toasts";
import MatchHeader from "../Header";
import SubmitBtn from "../formik components/SubmitBtn";

const PlayerSchema = Yup.object().shape({
  playerName: Yup.string().required("Required"),
  jerseyNumber: Yup.string().required("Required"),
  gender: Yup.string()
    .oneOf(
      ["MALE", "FEMALE", "OTHERS"],
      "Please enter a valid value ( MALE, FEMALE, OTHERS )"
    )
    .required("Required"),
  mobileNumber: Yup.string()
    .matches(
      /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/,
      "Invalid phone number"
    )
    .required("Required"),
});

const AddPlayersInTeam = ({ showModal, auth }) => {
  const playerDetail = auth.playerDetail;

  const [isActive, setIsActive] = useState(true);
  const [checked, setChecked] = useState(false);
  const queryClient = useQueryClient();
  const param = useParams();
  const { dataMatch: matchDetail } = useGetMatch({ id: param.matchId });

  // console.log(matchDetail);

  const dispatch = useDispatch();
  let playerDetailValues = {
    playerName: playerDetail?.name || "",
    jerseyNumber: playerDetail?.jerseyNumber || "",
    gender:
      playerDetail?.jerseyNumber || matchDetail?.matchType === "male"
        ? "MALE"
        : matchDetail?.matchType === "female"
        ? "FEMALE"
        : "" || "",
    mobileNumber: playerDetail?.mobileNumber || "",
  };

  const initialValues = playerDetailValues;

  const navigate = useNavigate();

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const data = {
      name: values.playerName,
      jerseyNumber: values.jerseyNumber,
      gender: values.gender,
      mobileNumber: values.mobileNumber,
    };

    // console.log("data", data);
    try {
      const res = await axiosInstance.patch(
        `/team/add/player/${param.teamId}`,
        data
      );
      // console.log(res);
      dispatch(setPlayerDetail({}));
      queryClient.invalidateQueries(["getTeamDetail", param.matchId]);
      // // console.log(response);
      toast.success("Player added successfully");
      navigate(`/myteam/edit/${param.teamId}`);
    } catch (err) {
      // console.log(err);
      setSubmitting(false);
      // console.log(err.response);
      const {
        response: {
          status,
          data: { message },
        },
      } = err;
      console.log(err);
      dispatch(
        addToast({
          kind: ERROR,
          msg: message,
        })
      );
      if (status === 409) {
        dispatch(setPlayerDetail({}));
        resetForm();
      }
    }
    // axiosInstance
    //   .post(`/match/addPlayer`, data)
    //   .then((response) => {
    //     dispatch(setPlayerDetail({}));
    //     queryClient.invalidateQueries(["getMatch", param.matchId]);
    //     // // console.log(response);
    //     toast.success("Player added successfully");
    //     navigate(`/mymatches/edit/${param.matchId}/${param.team}`);
    //   })
    //   .catch((err) => {
    //     setSubmitting(false);
    //     // console.log(err.response);
    //     const {
    //       response: {
    //         status,
    //         data: { error },
    //       },
    //     } = err;
    //     dispatch(
    //       addToast({
    //         kind: ERROR,
    //         msg: error,
    //       })
    //     );
    //     if (status == 409) {
    //       dispatch(setPlayerDetail({}));
    //       resetForm();
    //     }
    //   });
  };
  return (
    <div className="my-10 w-3/4 md:w-2/4">
      <MatchHeader
        title={matchDetail?.teamA?.name + " vs " + matchDetail?.teamB?.name}
      />
      <div className="rounded-xl shadow-card m-auto mb-7 px-8 py-8 drop-shadow-xl bg-white">
        <div className="flex items-center pt-4 border-b-2  border-b-secondary -mt-4">
          <Link
            to={`/myteam/edit/${param.teamId}`}
            className="font-semibold px-4 rounded-full"
          >
            <ArrowBack />
          </Link>
          <h1 className="font-semibold text-center text-xl w-[80%]">
            Add Players to Team
          </h1>
        </div>

        <h3 className="font-bold text-center">Player Details</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleSubmit(values, resetForm, setSubmitting)
          }
          validationSchema={PlayerSchema}
          enableReinitialize
        >
          {({
            handleChange,

            values,
            errors,
            touched,
            isSubmitting,
          }) => {
            // console.log(values);
            return (
              <Form>
                <input
                  type="text"
                  name="playerName"
                  value={values.playerName}
                  placeholder="Enter Player Name"
                  onChange={handleChange("playerName")}
                  className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                />
                {touched.playerName && errors.playerName && (
                  <p className="text-red-500 ml-4">{errors.playerName}</p>
                )}
                <input
                  type="text"
                  name="jerseyNumber"
                  value={values.jerseyNumber}
                  placeholder="Enter Jersey Number"
                  onChange={handleChange("jerseyNumber")}
                  className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                />
                {touched.jerseyNumber && errors.jerseyNumber && (
                  <p className="text-red-500 ml-4">{errors.jerseyNumber}</p>
                )}

                {!(
                  matchDetail?.matchType === "male" ||
                  matchDetail?.matchType === "female"
                ) && (
                  <select
                    name="gender"
                    value={values.gender}
                    onChange={handleChange("gender")}
                    placeholder="gender"
                    className="appearance-none bg-white text-black mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4] "
                  >
                    <option value="" hidden className="text-black">
                      Gender
                    </option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHERS">Others</option>
                  </select>
                )}
                <input
                  type="text"
                  name="mobileNumber"
                  value={values.mobileNumber}
                  placeholder="Enter Mobile Number"
                  onChange={handleChange("mobileNumber")}
                  className="appearance-none mt-5 block w-full border-2 rounded-xl py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                />
                {touched.mobileNumber && errors.mobileNumber && (
                  <p className="text-red-500 ml-4">{errors.mobileNumber}</p>
                )}
                {/* <div className="flex gap-2 items-center mx-3 my-3">
                  <input
                    name="isCaptain"
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                    type="checkbox"
                    id="isCaptain"
                    className="text-yellow-400"
                  />
                  <label htmlFor="isCaptain" className="font-semibold">
                    Captain
                  </label>
                </div> */}

                {/* <div className="flex gap-3 mx-3 my-2">
                  <input
                    name="isActive"
                    type="checkbox"
                    id="isActive"
                    className="mr-2 mt-1 text-yellow-400"
                    defaultChecked={isActive}
                    onChange={() => {
                      setIsActive(!isActive);
                    }}
                  />
                  <label htmlFor="isActive">Active</label>
                </div> */}
                <SubmitBtn
                  text={"Save"}
                  isSubmitting={isSubmitting}
                  containerClasses="mt-8 "
                  classes={"btn-primary"}
                  checkDirty={false}
                />
              </Form>
            );
          }}
        </Formik>
        <button
          onClick={() =>
            showModal({
              modalType: PLAYER_EXISTS_MODAL,
              modalTitle: "Find Existing Player by Contact Number",
            })
          }
          className="appearance-none rounded-xl  block w-full mt-4 -mb-2 bg-app-secondary border-0 py-2 focus:outline-none text-sm font-semibold text-black"
        >
          PLAYERS ALREADY EXISTS ?
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlayersInTeam);
