import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GameBar from "../../components/GameBar";
// import GameCategories from "../../components/GameCategories";
import SingleMatchesSection from "../../containers/MatchesPage/SingleMatchesSection";
import useGetMatch from "../../hooks/api/useGetMatch";
import { gameType } from "../../extras/constants";

const SingleMatchPage = () => {
  const { matchId } = useParams();
  const { dataMatch: matchDetail, isLoadingMatch } = useGetMatch({
    id: matchId,
  });
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    navigate("overview");
  }, []);
  let gameBarData = [];
  for (let [key, value] of Object.entries(gameType)) {
    gameBarData.push({
      title: key,
      link: value[0].link,
    });
  }
  return (
    <main className="flex items-center justify-center w-full flex-col mb-12 min-h-[100dvh]">
      <GameBar data={gameBarData} />
      {/* <GameCategories data={gameType[location.pathname.split("/")[2]]} /> */}
      <SingleMatchesSection data={matchDetail} isLoading={isLoadingMatch} />
    </main>
  );
};

export default SingleMatchPage;
