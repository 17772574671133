import React from "react";
import "../App.css";
import { appTitleOne, appTitleTwo } from "../csconfig";
const LoadingScreen = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={"/logo192.png"}
          className="App-logo animate-pulse"
          alt="logo"
        />
        <p className="text-3xl">
          <span className="text-app-primary">{appTitleOne}</span>
          <span className="text-app-secondary">4</span>
          <span className="text-app-primary">{appTitleTwo}</span>
        </p>
        {/* <p className="animate-pulse">
          <span className="text-app-primary">[ COMING </span>
          <span className="text-app-secondary">SOON ]</span>
        </p> */}
      </header>
    </div>
  );
};

export default LoadingScreen;
