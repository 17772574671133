import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import axiosInstance from "../axiosInstance";
import Header from "../components/Header";
import { ERROR, MATCH_STATUS, SUCCESS } from "../extras/constants";
import { isInvalidMatchBeforeStart } from "../extras/utils";
import useGetMatch from "../hooks/api/useGetMatch";
import { addToast } from "../redux/actions/toasts";
import EditCardShowPlayers from "./EditMatchBeforeStart/EditCardShowPlayers";

const SelectPlayer = ({ auth }) => {
  const { matchId, team } = useParams();
  const dispatch = useDispatch();
  const [isInValidMatch, setisInValidMatch] = useState(true);
  const [captainName, setCaptainName] = useState(null);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { dataMatch: matchDetail } = useGetMatch({ id: matchId });
  const teamDetail = team === "A" ? matchDetail?.teamA : matchDetail?.teamB;
  const queryClient = useQueryClient();
  const expectedActivePlayers = matchDetail?.threeByThree ? 3 : 5;

  useEffect(() => {
    const isValid = isInvalidMatchBeforeStart(matchDetail);
    // console.log("invalid Match", isValid);
    setisInValidMatch(isValid);
  }, [matchDetail]);

  // const getActivePlayers = () => {
  //   let activePlayersA = 0;
  //   let activePlayersB = 0;
  //   matchDetail.teamA.players.forEach((player) => {
  //     if (player.isActive) {
  //       activePlayersA++;
  //     }
  //   });
  //   matchDetail.teamB.players.forEach((player) => {
  //     if (player.isActive) {
  //       activePlayersB++;
  //     }
  //   });
  //   console.log(activePlayersA, activePlayersB, expectedActivePlayers);

  //   if (
  //     activePlayersA === expectedActivePlayers &&
  //     activePlayersB === expectedActivePlayers
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  const makeAllPlayersActive = async () => {
    if (matchDetail) {
      let teamA = [];
      let teamB = [];
      matchDetail?.teamA?.players.map((player) => {
        if (!player.isActive) {
          teamA.push(player);
        }
      });
      matchDetail?.teamB?.players.map((player) => {
        if (!player.isActive) {
          teamB.push(player);
        }
      });

      try {
        teamA.map(async (player) => {
          await axiosInstance.post("/match/changePlayerStatus", {
            playerId: player._id,
            isActive: true,
            matchId: matchId,
            team: "a",
          });
        });
        teamB.map(async (player) => {
          await axiosInstance.post("/match/changePlayerStatus", {
            playerId: player._id,
            isActive: true,
            matchId: matchId,
            team: "b",
          });
        });

        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "All players are now active for both teams!",
          })
        );
      } catch (error) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable to make all players active!",
          })
        );
      }
    }
  };

  const startMatch = async () => {
    // const isMatchReady = getActivePlayers();

    // if (!isMatchReady) {
    //   dispatch(
    //     addToast({
    //       kind: ERROR,
    //       msg: `Select exactly ${expectedActivePlayers} active players for both teams to start the match!`,
    //     })
    //   );
    //   setisInValidMatch(true);
    //   return;
    // }

    try {
      await makeAllPlayersActive();
    } catch (error) {
      console.log(error);
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Unable to make all players active!",
        })
      );
      return;
    }

    setIsLoading(true);
    await axiosInstance
      .post("/match/start", {
        matchId: matchId,
      })
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries(["myMatches", auth.accessToken]);
        queryClient.invalidateQueries("matchesList");
        // navigate(`/matchDetail/${matchId}/update/score`);
        navigate(`/scoring/${matchId}`);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Match Started Successfully!",
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);

        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable Start Match!",
          })
        );
      });
  };

  return (
    <div className="my-10 w-3/4 md:w-2/4">
      <Header
        title={matchDetail?.teamA?.name + " vs " + matchDetail?.teamB?.name}
      />

      <div className="rounded-xl m-auto px-6 py-5 shadow-card min-h-card bg-white flex flex-col h-full justify-between ">
        <div className="flex justify-around mb-3">
          <button
            onClick={() => navigate(`/mymatches/selectPlayer/${matchId}/A`)}
            className={`px-2 outline-none w-full border-b-4  ${
              team === "A" ? " border-app-secondary" : "border-white "
            } font-semibold `}
          >
            {matchDetail?.teamA?.name || "..."}
          </button>

          <button
            onClick={() => navigate(`/mymatches/selectPlayer/${matchId}/B`)}
            className={`px-2 outline-none  w-full border-b-4  ${
              team === "B" ? " border-app-secondary" : "border-white"
            } font-semibold `}
          >
            {matchDetail?.teamB?.name || "..."}
          </button>
        </div>

        <div>
          <EditCardShowPlayers
            team={team === "A" ? matchDetail?.teamA : matchDetail?.teamB}
            selectPlayer={true}
            setCaptainName={setCaptainName}
          />
        </div>
        {captainName ? (
          <div className="flex">
            <h4 className="text-xl font-semibold">Captain : {captainName}</h4>
          </div>
        ) : (
          <div className="flex">
            <h4 className="text-xl font-semibold">
              No captain decided for this team
            </h4>
          </div>
        )}
        <button
          className="btn-primary mt-6"
          disabled={isInValidMatch || isLoading}
          onClick={() => navigate(`/mymatches/edit/${matchId}/${team}`)}
        >
          ADD PLAYERS
        </button>
        <button
          className="btn-primary mt-3"
          disabled={
            isInValidMatch ||
            isLoading ||
            new Date(matchDetail?.dateAndTime) < new Date()
          }
          onClick={() => {
            window.scrollTo(0, 0);
            matchDetail?.status === MATCH_STATUS.UPCOMING
              ? startMatch()
              : navigate(`/matchDetail/${matchDetail._id}/update/score`);
          }}
        >
          {matchDetail?.status === MATCH_STATUS.LIVE
            ? "UPDATE GAME"
            : "START GAME"}
        </button>
        {matchDetail?.status === MATCH_STATUS.UPCOMING &&
          new Date(matchDetail?.dateAndTime) < new Date() && (
            <p
              style={{
                color: "red",
                textAlign: "center",
                margin: "10px",
              }}
            >
              You can not start the match before the defined start time.
              <br /> Start time updated? Edit the match as an organiser and
              update the start time to start the match.
            </p>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(SelectPlayer);
