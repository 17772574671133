import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

const LineChartCard = ({ teamData }) => {
  const data = {
    labels: ["Start", "Q1", "Q2", "Q3", "Q4"],
    datasets: [
      {
        label: "Team A",
        data: teamData.map((game) => game.teamA),
        borderColor: "rgba(0, 131, 72, 1)",
        borderWidth: 2,
        fill: false,
      },
      {
        label: "Team B",
        data: teamData.map((game) => game.teamB),
        borderColor: "rgba(93, 118, 169, 1)",
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        position: "bottom",
      },
      y: {
        position: "right",
      },
    },
  };

  return (
    <div className="w-full rounded-lg h-full flex items-center justify-center border">
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChartCard;
