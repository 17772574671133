import React, { useCallback, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  Position,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";
import "reactflow/dist/style.css";
import germanyFlag from "../../assets/flags/germany.svg";
import { nodes as initialNodes } from "./Nodes";
import { edges as initialEdges } from "./Edges";

const nodeDefaults = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
};

// const initialNodes = [
//   {
//     id: "1",
//     position: { x: 0, y: 150 },
//     data: { label: <img src={germanyFlag} alt="germany flag" /> },
//     ...nodeDefaults,
//   },
//   {
//     id: "2",
//     position: { x: 250, y: 0 },
//     data: { label: "default style 2" },
//     ...nodeDefaults,
//   },
//   {
//     id: "3",
//     position: { x: 250, y: 150 },
//     data: { label: "default style 3" },
//     ...nodeDefaults,
//   },
//   {
//     id: "4",
//     position: { x: 250, y: 300 },
//     data: { label: "default style 4" },
//     ...nodeDefaults,
//   },
// ];

// const initialEdges = [
//   {
//     id: "e1-2",
//     source: "1",
//     target: "2",
//     animated: true,
//   },
//   {
//     id: "e1-3",
//     source: "1",
//     target: "3",
//   },
//   {
//     id: "e1-4",
//     source: "1",
//     target: "4",
//   },
// ];

export default function MatchMaking() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  return (
    <div
      className="bg-off-white p-3 rounded-[12px] flex justify-center items-center border"
      style={{ height: "calc(60vh - 80px)" }}
    >
      <ReactFlow nodes={nodes} edges={edges}>
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}
