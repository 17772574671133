import React from "react";

const ProfilePhoto = ({ restClass, name, color }) => {
  const splitName = name ? name?.split(" ")?.join("+") : "Score+All";
  return (
    <img
      src={`https://eu.ui-avatars.com/api/?name=${splitName}&size=250&color=${color}`}
      alt="profile"
      className={`${restClass}`}
    />
  );
};

export default ProfilePhoto;
