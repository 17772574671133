import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { useQuery } from "react-query";
import { Link, Route, Routes, useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";

import ProfilePhoto from "../../components/ProfilePhoto/ProfilePhoto";
import First from "../../containers/Profile-player/First";
import MyMatches from "../../containers/Profile-player/MyMatches";
import MyParticipations from "../../containers/Profile-player/MyParticipations";
import MyTeams from "../../containers/Profile-player/MyTeams";
import MyTournaments from "../../containers/Profile-player/MyTournaments";
import Stats from "../../containers/Profile-player/Stats";
import { BASE_URL, LOGIN_MODAL } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";

const ProfileSection = ({ auth, showModal }) => {
  const { playerId, game } = useParams();
  const [myTeams, setMyTeams] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const pageNavs = [
    {
      name: "PROFILE & STATS",
      route: playerId ? `/profile/${playerId}` : `/profile/${game}/player`,
    },
    // {
    //   name: "OVERALL STATS",
    //   route: playerId
    //     ? `/profile/${playerId}/stats`
    //     : `/profile/${game}/player/stats`,
    // },
    !playerId && {
      name: "MY MATCHES",
      route: playerId
        ? `/profile/${playerId}/myMatches`
        : `/profile/${game}/player/myMatches`,
    },
    {
      name: "MY TOURNAMENTS",
      route: playerId
        ? `/profile/${playerId}/myTournaments`
        : `/profile/${game}/player/myTournaments`,
    },
    {
      name: "MY TEAMS",
      route: playerId
        ? `/profile/${playerId}/myteams`
        : `/profile/${game}/player/myteams`,
    },
    {
      name: "MY PARTICIPATION",
      route: playerId
        ? `/profile/${playerId}/myParticipation`
        : `/profile/${game}/player/myParticipation`,
    },
  ];

  const [profileStats, setProfileStats] = useState({
    matchesPlayed: 0,
    freeThrow: 0,
    "2P": 0,
    "3P": 0,
    totalPoints: 0,
    pointsPerMatch: 0,
    freeThrowPerMatch: 0,
    "2PPerMatch": 0,
    "3PPerMatch": 0,
  });

  const fetchProfile = async () => {
    try {
      if (playerId) {
        const res = await axiosInstance.get(
          `${BASE_URL}/player/getProfile/${playerId}`
        );
        return res.data;
      } else {
        let res = await axiosInstance.get(
          `${BASE_URL}/player/getProfileByToken`
        );
        // res = await axiosInstance.get(`${BASE_URL}/player/getProfileByToken`);
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: apiResponse,
    refetch,
    isLoading,
  } = useQuery(["fetchProfile", playerId], fetchProfile);

  console.log(apiResponse?.player);

  useEffect(() => {
    if (apiResponse?.player) {
      const {
        matchesPlayed,
        numberOfMatches,
        matchesOrganised,
        points: { one, two, three },
      } = apiResponse.player;

      setProfileStats({
        matchesPlayed: matchesPlayed?.length,
        freeThrow: one,
        "2P": parseInt(two / 2),
        "3P": parseInt(three / 3),
        totalPoints: parseInt(one + two + three),
        pointsPerMatch: parseFloat(
          (one + two + three) / (matchesPlayed?.length || 1)
        ).toFixed(2),
        freeThrowPerMatch: parseFloat(one / matchesPlayed?.length).toFixed(2),
        "2PPerMatch": parseFloat(
          parseInt(two / 2) / matchesPlayed?.length
        ).toFixed(2),
        "3PPerMatch": parseFloat(
          parseInt(three / 3) / matchesPlayed?.length
        ).toFixed(2),
      });
    }
  }, [apiResponse]);

  useEffect(() => {
    const fetchMyTeams = async () => {
      try {
        const res = await axiosInstance.get("/team/my");
        // console.log("fetch Team", res);
        setMyTeams(res.data.data);
      } catch (error) {
        // console.log("fetch my team error", error);
      }
    };
    fetchMyTeams();
  }, [refresh]);

  const birthdate = new Date(apiResponse?.player?.dateOfBirth);
  const today = new Date();
  const diffInMs = today - birthdate;
  const age = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365.25));

  if (!auth.isLoggedIn) {
    return (
      <div className="flex flex-col gap-2 py-10 items-center space-x-3">
        <p className="text-center font-semibold text-xl">
          Please Login to see Player Profiles
        </p>
        <button
          onClick={() =>
            showModal({
              modalType: LOGIN_MODAL,
            })
          }
          className="px-6 py-3 uppercase font-medium text-white hover:bg-app-secondary bg-black rounded-[15px] text-xs font-opsans transition duration-300"
        >
          Sign In
        </button>
      </div>
    );
  }

  return (
    <div className="font-mont">
      {auth.isLoggedIn ? (
        isLoading ? (
          <div className="text-center text-2xl py-20">Loading...</div>
        ) : (
          <div className="py-10 flex flex-col gap-10">
            <div className="flex flex-col gap-10 max-w-screen-xl w-full mx-auto px-3">
              {/* PLayer Card */}
              <div className="p-8 flex md:flex-row flex-col gap-16 flex-wrap w-full items-center border border-border-gray rounded-xl">
                <div className=" flex flex-col items-center gap-5">
                  <ProfilePhoto
                    name={apiResponse?.player?.name ?? "Player A"}
                    color="black"
                    restClass="w-28 h-28 rounded-full"
                  />
                  <p className="font-bold">{apiResponse?.player?.name}</p>
                </div>

                <div className="flex-1 h-full flex flex-col gap-5">
                  <div className="flex flex-col w-full">
                    <div className="flex w-full justify-between items-center text-[14px] font-bold">
                      <h3>COUNTRY</h3>
                      <h3>HEIGHT</h3>
                    </div>
                    <div className="flex w-full justify-between items-center text-[14px]">
                      <h3>--</h3>
                      <h3>{apiResponse?.player?.height ?? "- -"} cm</h3>
                    </div>
                  </div>
                  <div className="flex w-full flex-col">
                    <div className="flex w-full justify-between items-center text-[14px] font-bold">
                      <h3>TEAM</h3>
                      <h3>AGE</h3>
                    </div>
                    <div className="flex w-full justify-between items-center text-[14px]">
                      <h3>--</h3>
                      <h3>{age ? age : "- -"}</h3>
                    </div>
                  </div>

                  <div className="w-full rounded-lg py-5 px-5 bg-zinc-100 font-bold text-[14px] flex flex-col gap-3">
                    <p className="text-center"> ALL TIME</p>
                    <div className="flex flex-wrap items-start justify-evenly gap-7 text-center">
                      <div className="">
                        <h2>GAMES PLAYED</h2>
                        <p className="text-5xl">
                          {profileStats?.matchesPlayed}
                        </p>
                      </div>
                      <div className="">
                        <h2>PTS/GAME</h2>
                        <p className="text-5xl">
                          {profileStats?.pointsPerMatch}
                        </p>
                      </div>
                      <div className="">
                        <h2>FT/GAME</h2>
                        <p className="text-5xl">
                          {profileStats?.freeThrowPerMatch !== "NaN"
                            ? profileStats?.freeThrowPerMatch
                            : "- -"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* in-page navigation */}

            <div className="w-screen  shadow-md overflow-hidden">
              <div className="max-w-screen-xl mx-auto">
                <div className="flex gap-7 items-center font-semibold py-4 font-mont text-[15px] overflow-x-auto md:px-0 px-2">
                  {pageNavs.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.route}
                        className={`rounded-md px-2 py-1 min-w-max
                        ${
                          window.location.pathname === item.route
                            ? "text-app-orange"
                            : "hover:text-app-orange"
                        }
                      `}
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* In Page Routes */}
            <div className="max-w-screen-xl w-full mx-auto py-10 -mt-10 overflow-hidden">
              <div className="flex flex-col gap-10 px-3">
                <Routes>
                  <Route
                    path=""
                    element={
                      <First
                        apiResponse={apiResponse}
                        profileStats={profileStats}
                      />
                    }
                  />
                  <Route
                    path="/stats"
                    element={<Stats profileStats={profileStats} />}
                  />
                  <Route path="/myTournaments" element={<MyTournaments />} />
                  <Route path="/myMatches" element={<MyMatches />} />
                  <Route
                    path="/myteams"
                    element={
                      <MyTeams teams={apiResponse?.player?.teamsPlayed} />
                    }
                  />
                  <Route
                    path="/myParticipation"
                    element={<MyParticipations />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="p-3">
          <p className="text-center">Please Login to see Player Profiles</p>
          <button
            onClick={() => {
              // console.log('hello');
              showModal({
                modalType: LOGIN_MODAL,
              });
            }}
            className="m-auto block py-2 px-4 font-medium rounded bg-app-secondary text-white"
          >
            Log In
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSection);
