import React from "react";

export default function StandingGroupTable({ data, group }) {
  const headings = [
    "",
    "Team",
    "P",
    "W",
    "L",
    "%",
    "LAST 5",
    "AGG",
    "FA",
    "AA",
    "PTS",
  ];
  const sampleData = [
    {
      no: 1,
      name: "Team 1",
      played: 5,
      win: 3,
      loss: 2,
      percentage: "60%",
      last5: ["W", "W", "W", "L", "L"],
      agg: 10,
      fa: 10,
      aa: 10,
      pts: 10,
    },
    {
      no: 1,
      name: "Team 1",
      played: 5,
      win: 3,
      loss: 2,
      percentage: "60%",
      last5: ["W", "W", "W", "L", "L"],
      agg: 10,
      fa: 10,
      aa: 10,
      pts: 10,
    },
    {
      no: 1,
      name: "Team 1",
      played: 5,
      win: 3,
      loss: 2,
      percentage: "60%",
      last5: ["W", "W", "W", "L", "L"],
      agg: 10,
      fa: 10,
      aa: 10,
      pts: 10,
    },
    {
      no: 1,
      name: "Team 1",
      played: 5,
      win: 3,
      loss: 2,
      percentage: "60%",
      last5: ["W", "W", "W", "L", "L"],
      agg: 10,
      fa: 10,
      aa: 10,
      pts: 10,
    },
  ];
  return (
    <div className="overflow-auto rounded-[12px] border ">
      <h1 className="bg-app-orange uppercase font-bold px-3 py-2">
        Group&nbsp;
        {group ? group : "Unknown"}
      </h1>
      <table className="w-full  overflow-hidden text-sm text-center border-collapse">
        <thead>
          {headings.map((heading) => {
            return (
              <th className="border-r last:border-none py-1 uppercase text-zinc-800 font-semibold">
                {heading}
              </th>
            );
          })}
        </thead>
        <tbody className="font-bold">
          {sampleData.map((data, index) => (
            <tr>
              <td className="border-r border-t p-1">{data.no}</td>
              <td className="border-r border-t p-1">{data.name}</td>
              <td className="border-r border-t p-1">{data.played}</td>
              <td className="border-r border-t p-1">{data.win}</td>
              <td className="border-r border-t p-1">{data.loss}</td>
              <td className="border-r border-t p-1">{data.percentage}</td>
              <td className="border-r border-t p-1 flex gap-1 justify-center">
                {data.last5.map((item) => {
                  return (
                    <p
                      className={`${
                        item?.toLowerCase() === "w"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {item}
                    </p>
                  );
                })}
              </td>
              <td className="border-r border-t p-1">{data.agg}</td>
              <td className="border-r border-t p-1">{data.fa}</td>
              <td className="border-r border-t p-1">{data.aa}</td>
              <td className="border-r border-t p-1">{data.pts}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
