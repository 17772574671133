import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import DatePicker from "../../components/DatePicker";
import MatchesTeamCard from "../MatchesPage/MatchesTeamCard";
import Selector from "../../components/DropDown/Selector";

export default function Games({ data }) {
  const location = useLocation();
  const isMyTournament = location.search.split("=")[1];

  console.log("Games tournament", data.matches);
  const [filteredData, setFilteredData] = useState(data?.matches);
  const options = {
    filter1: ["ALL", "UPCOMING", "FINISHED", "LIVE"],
  };
  const [form, setForm] = React.useState({
    date: new Date().toISOString().split("T")[0],
  });
  const [filter1, setFilter1] = useState(options.filter1[0]);

  const handleSearch = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const filtered = data.matches.filter((match) => {
        return (
          match?.teamA?.name.toLowerCase().includes(search.toLowerCase()) ||
          match?.teamB?.name.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data.matches);
    }
  };

  useEffect(() => {
    if (filter1 === "ALL") {
      setFilteredData(data.matches);
    } else {
      const filtered = data.matches.filter((match) => {
        return match?.status?.toLowerCase().includes(filter1.toLowerCase());
      });
      setFilteredData(filtered);
    }
  }, [filter1]);

  return (
    <div className="flex flex-col gap-10">
      <DatePicker form={form} setForm={setForm} />
      <div className="flex flex-col gap-6 min-h-56">
        <div className="flex gap-5 justify-between items-center">
          <Selector
            options={options.filter1}
            setFilter={setFilter1}
            filter={filter1}
          />
          <input
            type="text"
            name="search"
            id="search"
            className="border-b outline-none w-56 focus:border-b-app-orange transition-all duration-300"
            placeholder="Search team name"
            autoComplete="off"
            onChange={handleSearch}
          />
        </div>
        {filteredData &&
          filteredData.length > 0 &&
          filteredData.map((item, key) => (
            <MatchesTeamCard data={item} key={key} isMyMatch={isMyTournament} />
          ))}
      </div>
    </div>
  );
}
