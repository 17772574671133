import { Delete } from "@mui/icons-material";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { TEAM_DELETE_CONFIRMATION_MODAL } from "../../extras/constants";
import { SHOW_MODAL } from "../../redux/actions/types";

function EditTeamShowPlayers({ team }) {
  const viewOnly = false;
  // console.log(params);
  let headers = ["Jersey No.", "Player", "Delete"];
  console.log(team);

  const { teamId } = useParams();

  const dispatch = useDispatch();

  return (
    <>
      {team?.currentPlayers?.length > 0 && (
        <table className="w-full border-collapse border my-2 ">
          <tr>
            {headers.map((label, index) => (
              <th
                key={index}
                className="text-center  p-2 border-r-2 border-b-2 boder-[#ddd]"
              >
                {label}
              </th>
            ))}
          </tr>
          {team?.currentPlayers
            .sort((a, b) => a?._id?.jerseyNumber - b?._id?.jerseyNumber)
            .map((player, index) => (
              <tr
                key={index}
                onClick={() => {}}
                className={`border-b ${player.isActive && "hover:bg-[#ddd]"} ${
                  viewOnly ? "" : "cursor-pointer"
                }`}
              >
                <td className="text-center p-2 border-r-2 border-[#ddd] relative">
                  {player?.jerseyNumber
                    ? player?.jerseyNumber
                    : player?._id?.jerseyNumber}{" "}
                </td>
                <td className="text-center p-2 border-r-2 border-[#ddd] relative">
                  {player?.name}
                </td>

                <td
                  onClick={() => {
                    // console.log("Clicked");
                    dispatch({
                      type: SHOW_MODAL,
                      payload: {
                        modalType: TEAM_DELETE_CONFIRMATION_MODAL,
                        modalTitle:
                          "Are you sure you want to delete this player?",
                        modalProps: {
                          player,
                          teamId,
                        },
                      },
                    });
                  }}
                  className="text-center p-2 border-r-2 border-[#ddd] "
                >
                  <Delete className="text-[#F53535]" />
                </td>

                {/* {selectPlayer ? null : (
                <td className="text-center p-2 border-r-2 border-[#ddd] ">
                  <button
                    onClick={() => {
                      dispatch({
                        type: SHOW_MODAL,
                        payload: {
                          modalType: EDIT_PLAYER_MODAL,
                          modalTitle: "Edit Player",
                          modalProps: {
                            player,
                            matchId,
                            team: paramsTeam.toLowerCase(),
                          },
                        },
                      });
                    }}
                    className="text-white px-2 py-1 rounded-lg"
                  >
                    <Edit className="text-gray-500" />
                  </button>
                </td>
              )} */}
              </tr>
            ))}
        </table>
      )}
    </>
  );
}

export default EditTeamShowPlayers;
