import React from "react";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

export default function TeamProfileCard({ team }) {
  return (
    <div className="p-8 flex md:flex-row flex-col gap-16 flex-wrap w-full items-center border border-border-gray rounded-xl">
      <div className=" flex flex-col items-center gap-5">
        <ProfilePhoto
          name={team?.name ?? "ScoreAll"}
          color="black"
          restClass="w-28 h-28 rounded-full"
        />
        <p className="font-bold">{team?.name}</p>
      </div>

      <div className="flex-1 h-full flex flex-col gap-5">
        <div className="w-full rounded-lg py-5 px-5 bg-zinc-100 font-bold text-[14px] flex flex-col gap-3">
          <div className="flex flex-wrap items-start justify-evenly gap-7 text-center">
            {team?.matches && (
              <div className="">
                <h2>GAMES PLAYED</h2>
                <p className="text-5xl">{team?.matches.length}</p>
              </div>
            )}
            <div className="">
              <h2>NO OF PLAYERS</h2>
              <p className="text-5xl">{team?.allPlayers.length}</p>
            </div>
            <div className="">
              <h2>TOTAL POINTS</h2>
              <p className="text-5xl">{team?.totalPoints}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
