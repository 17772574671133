import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import GameBar from "../../components/GameBar";
import GameCategories from "../../components/GameCategories";
import AllTournaments from "./AllTournaments";
import CreateTournament from "./CreateTournament";
import Nba from "./Nba";
import { gameType } from "../../extras/constants";

export default function TheTournament() {
  const location = useLocation();
  let gameBarData = [];
  for (let [key, value] of Object.entries(gameType)) {
    gameBarData.push({
      title: key,
      link: value[0].link,
    });
  }
  return (
    <main className="flex w-full flex-col mb-12 min-h-[100vh]">
      <GameBar data={gameBarData} />
      <GameCategories data={gameType[location.pathname.split("/")[2]]} />
      <Routes>
        <Route path="/:id/*" element={<Nba />} />
        <Route path="/allothertournaments" element={<AllTournaments />} />
        <Route path="/createTournament" element={<CreateTournament />} />
        <Route path="*" element={<Navigate to="/tournaments" />} />
      </Routes>
    </main>
  );
}
