import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import React from "react";
import { Link } from "react-router-dom";
import FooterSec from "../components/FooterSec";


function Footer() {
  return (
    <>
      <footer className="w-full flex items-center justify-center flex-col">
        <FooterSec text={"You’ve got something to say.<br/>We’ve got people who want to hear it."}/>
        <div className="w-full flex items-center justify-center p-2 bg-black">
          <div className="w-full flex items-center justify-center flex-col gap-8 p-2">
            <div className="flex items-center justify-center w-full relative">
              <div className="flex items-center justify-center w-full h-full">
                <p className="text-[4rem] xs:text-[5rem] sm:text-9xl xl:text-[200px] leading-[1] font-semibold font-barlow text-[#1c1c1c]">
                  subscribe
                </p>
              </div>
              <div className="absolute w-full h-full flex items-center justify-center mt-4 xs:mt-6 sm:mt-10 xl:mt-14">
                <div className="relative flex items-center justify-center w-full max-w-[15rem]">
                  <input
                    type="email"
                    placeholder="Your email"
                    className="w-full h-full pr-8 pl-3 py-2 text-xs rounded-[10px] bg-[#D9DBDF] border border-white text-[#7E7F81]  font-mont font-semibold  focus:outline-none"
                  />
                  <button className="absolute right-0 top-0 w-20 h-full bg-app-orange rounded-[10px] text-white font-mont font-semibold text-xs focus:outline-none">
                    Send
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between max-w-5xl flex-wrap sm:flex-nowrap gap-2 w-full p-2 text-white/40">
              <div className="flex items-center justify-center text-white/60 p-1 font-semibold font-mont uppercase text-xs gap-6">
                <Link to="/">
                  <p>About</p>
                </Link>
                <Link to="/">
                  <p>Contact Us</p>
                </Link>
                <Link to="/">
                  <p>Help</p>
                </Link>
              </div>
              <div className="flex items-center justify-center p-1 gap-2 pr-6">
                <InstagramIcon />
                <TwitterIcon />
                <FacebookIcon />
              </div>
              <div className="flex items-center justify-center p-1 pr-6">
                <p className="text-sm font-mont">© 2020 Score4all.com</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;