import React from "react";
import { Route, Routes, useLocation } from "react-router";
import Footer from "../../containers/Footer";
import Navbar from "../../containers/Navbar";

import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import AddPlayersInTeam from "../../components/CreateTeam/AddPlayersInTeam";
import CreateTeam from "../../components/CreateTeam/CreateTeam";
import EditTeamPlayers from "../../components/CreateTeam/EditTeamPlayers";
import ScoringCard from "../../components/Scoring/ScoringCard";
import AddCoachDetails from "../../containers/AddCoachDetails";
import AddPlayerDetails from "../../containers/AddPlayerDetails";
import CreateMatch from "../../containers/Common/CreateMatch";
import SelectPlayer from "../../containers/SelectPlayer";
import EditMatch from "../EditMatch";
import HomePage from "../HomePage";
import MatchesPage from "../MatchPage/MatchesPage";
import SingleMatchPage from "../MatchPage/SingleMatchPage";
import Profile from "../Profile/Profile";
import TheTournament from "../Tournament/TheTournament";

const RefactorLayout = () => {
  const location = useLocation();
  const isTournamentRoute =
    location.pathname?.includes("tournaments") ||
    location.pathname?.includes("myGames");

  return (
    <div className="layout">
      <div className="top-0 z-50 sticky">
        <Navbar />
      </div>
      <div className="flex  justify-center w-full h-full">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile/*" element={<Profile />} />
          <Route path="/matches/:gameType" element={<MatchesPage />} />
          <Route
            path="/matches/:gameType/createMatch"
            element={<CreateMatch />}
          />
          <Route path="/createTeam" element={<CreateTeam />} />
          <Route path="/myteam/edit/:teamId" element={<EditTeamPlayers />} />
          <Route
            path="/myteam/addplayer/:teamId"
            element={<AddPlayersInTeam />}
          />
          <Route
            path="/matches/:gameType/:matchType"
            element={<MatchesPage />}
          />

          <Route
            path="/matches/:gameType/:matchType/:matchId"
            element={<SingleMatchPage />}
          >
            <Route
              path="/matches/:gameType/:matchType/:matchId/:tabs"
              element={<SingleMatchPage />}
            />
          </Route>
          <Route
            path="/mymatches/edit/:matchId/:team"
            element={<EditMatch />}
          />
          <Route
            path="/mymatches/addplayer/:team/:matchId/:teamName"
            element={<AddPlayerDetails />}
          />
          <Route
            path="/mymatches/addcoach/:team/:matchId/:teamName"
            element={<AddCoachDetails />}
          />
          <Route
            path="/mymatches/selectPlayer/:matchId/:team"
            element={<SelectPlayer />}
          />
          {/* Tournament Routes */}
          <Route
            path="/tournaments/:gameType/*" //? /tournaments/basketball
            element={<TheTournament />}
          />
          <Route path="/scoring/:matchId" element={<ScoringCard />} />
        </Routes>
      </div>
      <div className="flex justify-center items-center">
        <Footer />
      </div>
    </div>
  );
};

export default RefactorLayout;
