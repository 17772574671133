import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "../../components/DatePicker";
import MatchesTeamCard from "./MatchesTeamCard";

const MatchesSection = ({
  title = "",
  data,
  recentMatches,
  isLoadingMatch,
  form,
  setForm,
}) => {
  console.log(data, "data", recentMatches, "recentMatches");
  const [matches, setMatches] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const inputRef = useRef();

  const handleIconClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const filteredFinished = data?.finished?.filter(
      (item) => item.dateAndTime.split("T")[0] === form.date
    );
    const filteredLive = data?.live?.filter(
      (item) => item.dateAndTime.split("T")[0] === form.date
    );
    const filteredUpcoming = data?.upcoming?.filter(
      (item) => item.dateAndTime.split("T")[0] === form.date
    );
    console.log(filteredFinished, filteredLive, filteredUpcoming);
    if ((filteredFinished, filteredUpcoming, filteredLive)) {
      setMatches(() => [
        ...filteredFinished,
        ...filteredLive,
        ...filteredUpcoming,
      ]);
    }
  }, [form.date, data]);

  // First useEffect
  // useEffect(() => {
  //   if (recentMatches && recentMatches.length > 0) {
  //     setForm((prevForm) => ({
  //       ...prevForm,
  //       date: new Date(recentMatches[0]?._id),
  //     }));
  //   }
  // }, [recentMatches]);

  // Second useEffect
  useEffect(() => {
    if (matches && !isLoadingMatch && recentMatches) setLoading(false);
    // Perform other actions that depend on form.date here
  }, [form.date, matches, isLoadingMatch, recentMatches]);

  console.log(matches, "matches");
  return (
    <section className="w-full h-full flex items-start justify-start p-2">
      <div className="flex items-center justify-center w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-center flex-col gap-6 w-full py-6">
          <div className="flex items-start justify-between w-full xs:flex-row flex-col">
            <h1 className="text-sm xs:text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold uppercase font-mont text-black">
              {title ? title : "All"} Matches
            </h1>
            <div className="relative flex items-center pointer-events-auto cursor-pointer place-self-end justify-center">
              <CalendarMonthIcon
                className="text-black pointer-events-none absolute right-0 top-0  z-10"
                onClick={handleIconClick}
              />
              <input
                ref={inputRef}
                type="date"
                name="date"
                id="date"
                onChange={handleChange}
                value={form.date}
                className="opacity-0"
              />
            </div>
          </div>
          <div className="flex items-center justify-center gap-4 w-full">
            <DatePicker form={form} setForm={setForm} data={data} />
          </div>
          <div className="flex items-center justify-center gap-4 w-full">
            {isLoadingMatch ? (
              <div className="text-center">
                <p>Loading Recent Matches</p>
              </div>
            ) : (
              recentMatches &&
              recentMatches.length > 0 &&
              recentMatches.map((item) => {
                const date = new Date(item?.date).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                });
                return (
                  <button
                    key={item?._id}
                    onClick={() =>
                      setForm({
                        ...form,
                        date: item?._id,
                      })
                    }
                    className="flex items-center rounded bg-app-orange text-black font-bold flex-col px-8 py-2"
                  >
                    <p>{date}</p>
                    <div className="flex gap-2  items-center justify-center">
                      <p>Total Matches</p>
                      <p>{item.matches.length}</p>
                    </div>
                  </button>
                );
              })
            )}
          </div>
          <div className="flex items-center justify-center w-[95%] flex-col gap-8">
            {loading ? (
              <div className="w-full rounded-lg bg-zinc-100 animate-pulse h-[30rem] p-5 flex flex-col gap-4">
                <div className="bg-zinc-300 animate-pulse h-9 rounded-lg"></div>
                <div className="h-full bg-zinc-200 animate-pulse rounded-lg"></div>
                <div className="bg-zinc-300 animate-pulse h-36 rounded-lg"></div>
              </div>
            ) : matches && matches.length > 0 ? (
              matches.map((item) => (
                <Link
                  to={`/matches/basketball/s4a/${item._id}/overview`}
                  key={item._id}
                  className="flex items-center justify-center w-full"
                >
                  <MatchesTeamCard data={item} />
                </Link>
              ))
            ) : (
              <p className="w-full text-center text-xl items-center text-black">
                No matches found for this date
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MatchesSection;
