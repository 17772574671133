//modal types
export const LOGIN_MODAL = "LOGIN_MODAL";
export const CREATE_ACCOUNT_MODAL = "CREATE_ACCOUNT_MODAL";
export const VERIFY_OTP_MODAL = "VERIFY_OTP_MODAL";
export const PLAYER_EXISTS_MODAL = "PLAYER_EXISTS_MODAL";
export const DELETE_CONFIRMATION_MODAL = "DELETE_CONFIRMATION_MODAL";
export const TEAM_DELETE_CONFIRMATION_MODAL = "TEAM_DELETE_CONFIRMATION_MODAL";
export const EDIT_PLAYER_MODAL = "EDIT_PLAYER_MODAL";
export const EDIT_MATCH_MODAL = "EDIT_MATCH_MODAL";

// toasts
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const INFO = "INFO";
export const WARNING = "WARNING";
export const DEFAULT = "DEFAULT";

// api url
export const BASE_URL = process.env.REACT_APP_API_URL;

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const MATCH_TYPE = {
  ORGANIZED: "organized",
  PARTICIPATED: "participated",
};
export const MATCH_STATUS = {
  FINISHED: "finished",
  UPCOMING: "upcoming",
  LIVE: "live",
};

export const gameType = {
  basketball: [
    {
      title: "All Tournaments",
      link: "/tournaments/basketball/allothertournaments",
    },
    // {
    //   title: "Create Match",
    //   link: "/tournaments/basketball/createMatch",
    // },
    {
      title: "Create Tournament",
      link: "/tournaments/basketball/createTournament",
    },
  ],
  football: [
    {
      title: "Premier League",
      link: "/matches/football/premierLeague",
    },
    {
      title: "Ligue 1",
      link: "/matches/football/ligue1",
    },
    {
      title: "UEFA Champions League",
      link: "/matches/football/uefaChampionsLeague",
    },
    {
      title: "UEFA Europa League",
      link: "/matches/football/uefaEuropaLeague",
    },
    {
      title: "All Other Tournaments",
      link: "/matches/football/allothertournaments",
    },
    {
      title: "Create Tournament",
      link: "/tournaments/basketball/createTournament",
    },
  ],
  badminton: [
    {
      title: "IPL",
      link: "/matches/badminton/ipl",
    },
    {
      title: "All Other Tournaments",
      link: "/matches/badminton/allothertournaments",
    },
  ],
  tabletennis: [
    {
      title: "ATP",
      link: "/matches/tabletennis/atp",
    },
    {
      title: "WTA",
      link: "/matches/tabletennis/wta",
    },
    {
      title: "All Other Tournaments",
      link: "/matches/tabletennis/allothertournaments",
    },
  ],
  othersports: [
    {
      title: "Boxing",
      link: "/matches/othersports/boxing",
    },
    {
      title: "MMA",
      link: "/matches/othersports/mma",
    },
    {
      title: "Kabaddi",
      link: "/matches/othersports/kabaddi",
    },
    {
      title: "All Other Sports",
      link: "/matches/othersports/other",
    },
  ],
};
