import React from "react";
import { Link } from "react-router-dom";
import heroImg from "../../assets/heroImg.svg";

const HeroSection = () => {
  return (
    <section className="flex w-full min-h-[100dvh] items-start justify-start">
      <div className="flex items-center justify-center w-full bg-hero bg-no-repeat bg-[center_bottom_1rem] bg-cover ">
        <div className="flex items-center justify-center w-full flex-col gap-4">
          <div className="flex items-center justify-center w-full flex-col md:flex-row xl:gap-12">
            <div className="flex items-start justify-center w-full flex-col gap-6 lg:gap-12 p-2 lg:pl-7 xl:ml-24 2xl:ml-[8.5rem]">
              <h1 className="text-4xl xs:text-5xl sm:text-6xl mt-12 md:mt-0 md:text-5xl lg:text-6xl text-black font-benzin">
                Digitizing <br />
                Scoresheet
                <br /> since <span className="text-white">Aug 22'</span>
              </h1>
              <p className="font-light font-mont md:text-base lg:text-lg">
                "Revolutionizing scorekeeping since August 22, our digital
                scoresheets offer a seamless and efficient way to track and
                manage scores. Say goodbye to manual errors and hello to
                precision. Embrace the future of scoring with our innovative
                digitized solution, redefining the way you keep score in sports,
                games, and beyond."
              </p>
              <div className="flex items-start justify-start gap-4 flex-col w-full">
                <Link
                  to="matches/basketball"
                  className="uppercase bg-white text-center font-opsans text-lg w-full sm:w-[75%] flex-1 font-bold rounded-[10px] px-7 py-3"
                >
                  VIEW MATCHES
                </Link>

                <div className="flex items-start justify-start gap-4 sm:flex-row flex-col w-full sm:w-[75%]">
                  <Link
                    to="/matches/basketball/createMatch"
                    className="uppercase bg-white text-center font-opsans text-lg w-full sm:w-[75%] flex-1 font-bold rounded-[10px] px-7 py-3"
                  >
                    Create a Match
                  </Link>

                  <button
                    onClick={() => window.open("mailto:s4a.india@gmail.com")}
                    className="uppercase bg-white font-opsans text-lg w-full flex-1 font-bold rounded-[10px] px-7 py-3"
                  >
                    contact us
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden md:flex items-end justify-end w-full py-12 pl-2">
              <img
                src={heroImg}
                alt="heroImg"
                className="w-auto h-[40rem] object-contain"
              />
            </div>
          </div>
          <div className="flex items-center h-[12rem] w-full"></div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
