import React, { useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { useQuery } from "react-query";
import MatchesTeamCard from "../MatchesPage/MatchesTeamCard";
import { Link } from "react-router-dom";

export default function MyParticipations() {
  const auth = useSelector((state) => state.auth);
  const fetchMyMatches = async () => {
    const res = await axiosInstance.get(`/match/getMyParticipatedMatches`);
    let data = [
      ...res?.data?.live,
      ...res?.data?.upcoming,
      ...res?.data?.finished,
    ];

    data.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return data;
  };

  const {
    data: apiResponse,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(["myParticipated", auth.accessToken], fetchMyMatches);
  return (
    <>
      <div className="flex flex-col gap-2">
        {apiResponse?.map((item, index) => (
          <Link
            to={`/matches/basketball/${item.threeByThree ? "3*3" : "5*5"}/${
              item?._id
            }`}
            target="_blank"
            key={index}
          >
            <MatchesTeamCard key={index} data={item} isMyMatch={false} />
          </Link>
        ))}
      </div>
    </>
  );
}
