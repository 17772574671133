import React, { useState } from "react";
import MatchesTeamCard from "../MatchesPage/MatchesTeamCard";
import axiosInstance from "../../axiosInstance";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import Card from "../../components/Tournament/Card";

export default function MyTournaments() {
  const heading = [
    "MATCH",
    "PTS/40",
    "R/40",
    "A/40",
    "TS%",
    "AST",
    "TO",
    "USG",
    "REBR",
    "RPM",
  ];
  const tableValue = [
    {
      match: "BV",
      pts: "20",
      r: "20",
      a: "00",
      ts: "00",
      ast: "00",
      to: "00",
      usg: "00",
      rebr: "00",
      rpm: "00",
    },
    {
      match: "TY",
      pts: "20",
      r: "20",
      a: "00",
      ts: "00",
      ast: "00",
      to: "00",
      usg: "00",
      rebr: "00",
      rpm: "00",
    },
    {
      match: "MNH",
      pts: "20",
      r: "20",
      a: "00",
      ts: "00",
      ast: "00",
      to: "00",
      usg: "00",
      rebr: "00",
      rpm: "00",
    },
    {
      match: "MHM",
      pts: "20",
      r: "20",
      a: "00",
      ts: "00",
      ast: "00",
      to: "00",
      usg: "00",
      rebr: "00",
      rpm: "00",
    },
    {
      match: "LIK",
      pts: "20",
      r: "20",
      a: "00",
      ts: "00",
      ast: "00",
      to: "00",
      usg: "00",
      rebr: "00",
      rpm: "00",
    },
    {
      match: "BOS VS LAL",
      pts: "20",
      r: "20",
      a: "00",
      ts: "00",
      ast: "00",
      to: "00",
      usg: "00",
      rebr: "00",
      rpm: "00",
    },
  ];

  const [matches, setMatches] = useState({});
  const auth = useSelector((state) => state.auth);

  const fetchMyTournaments = async () => {
    const res = await axiosInstance.get(`/tournament/my`);
    console.log(res);
    // let data = [
    //   ...res?.data?.live,
    //   ...res?.data?.upcoming,
    //   ...res?.data?.finished,
    // ];

    // data.sort((a, b) => {
    //   return new Date(b.date) - new Date(a.date);
    // });
    return res.data.data;
  };

  const {
    data: apiResponse,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(["fetchMyTournaments"], fetchMyTournaments);

  console.log(apiResponse);

  return (
    <>
      <div className="flex flex-col gap-2">
        {/* <div className="border border-border-gray rounded-xl overflow-x-auto">
          <table className="w-full text-center table-fixed">
            <thead className="bg-app-orange">
              {heading.map((item, index) => (
                <th
                  key={index}
                  className={`py-3 min-w-[100px] w-[100px]  ${
                    item === "MATCH" && ""
                  }`}
                >
                  {item}
                </th>
              ))}
            </thead>
            <tbody className="text-sm font-medium text-zinc-700">
              {tableValue.map((item, index) => (
                <tr key={index}>
                  <td className="py-2 border-r last:border-none">
                    {item.match}
                  </td>
                  <td className="py-2 border-r last:border-none">{item.pts}</td>
                  <td className="py-2 border-r last:border-none">{item.r}</td>
                  <td className="py-2 border-r last:border-none">{item.a}</td>
                  <td className="py-2 border-r last:border-none">{item.ts}</td>
                  <td className="py-2 border-r last:border-none">{item.ast}</td>
                  <td className="py-2 border-r last:border-none">{item.to}</td>
                  <td className="py-2 border-r last:border-none">{item.usg}</td>
                  <td className="py-2 border-r last:border-none">
                    {item.rebr}
                  </td>
                  <td className="py-2 border-r last:border-none">{item.rpm}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end font-medium text-sm">
          <button>VIEW ALL</button>
        </div> */}

        <div className="flex flex-col gap-2">
          {apiResponse?.map((item, index) => (
            <Card key={index} data={item} isMyMatch={true} isMyTournament={true} />
          ))}
        </div>
      </div>
    </>
  );
}
