import React from "react";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
import LineChartCard from "./LineChartCard";

const GameFlow = ({ data, teamAName, teamBName }) => {
  //data structure => [{teamA: 50, teamB: 60}, {teamA: 55, teamB: 58}, {teamA: 45, teamB: 70}]
  //we are getting => {teamA: {1: 50, 2: 55, 3: 45}, teamB: {1: 60, 2: 58, 3: 70}}
  let teamData = [
    { teamA: 0, teamB: 0 },
    { teamA: data?.teamA[1], teamB: data?.teamB[1] },
    {
      teamA: data?.teamA[2] + data?.teamA[1],
      teamB: data?.teamB[2] + data?.teamB[1],
    },
    {
      teamA: data?.teamA[3] + data?.teamA[2] + data?.teamA[1],
      teamB: data?.teamB[3] + data?.teamB[2] + data?.teamB[1],
    },
    {
      teamA: data?.teamA[4] + data?.teamA[3] + data?.teamA[2] + data?.teamA[1],
      teamB: data?.teamB[4] + data?.teamB[3] + data?.teamB[2] + data?.teamB[1],
    },
  ];
  return (
    <div className="w-full h-full flex flex-col font-mont items-center col-span-1 justify-center px-4 py-2 rounded-[12px] border border-[#E6E6E6]">
      <div className="w-full flex items-center justify-start p-2 border-b border-[#E4E4E4]">
        <p className="font-bold uppercase text-base">Game Flow</p>
      </div>
      <div className="w-full flex items-center justify-start gap-8 py-4 px-2">
        <div className="flex items-center justify-center gap-6">
          <div className="flex items-center justify-center relative">
            <div className="h-[8px] w-[3rem] bg-secondaryA"></div>
            <ProfilePhoto
              name={teamAName}
              color="black"
              restClass="w-5 h-5 rounded-full absolute -right-2"
            />
          </div>
          <p className="font-medium text-xs">{teamAName}</p>
        </div>
        <div className="flex items-center justify-center gap-6">
          <div className="flex items-center justify-center relative">
            <div className="h-[8px] w-[3rem] bg-secondaryB"></div>
            <ProfilePhoto
              name={teamBName}
              color="black"
              restClass="w-5 h-5 rounded-full absolute -right-2"
            />
          </div>
          <p className="font-medium text-xs">{teamBName}</p>
        </div>
      </div>
      <div className="flex items-center justify-start p-2 flex-col gap-1 w-full">
        <LineChartCard teamData={teamData} />
      </div>
    </div>
  );
};

export default GameFlow;
