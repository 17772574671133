import React from "react";
import TeamProfileCard from "../../components/profile/TeamProfileCard";
import { Link } from "react-router-dom";

export default function MyTeams({ teams }) {
  return (
    <div className="flex flex-col gap-5">
      {teams && teams.length > 0 ? (
        teams.map((team) => (
          <Link to={`/profile/basketball/team/${team._id}`} key={team._id}>
            <TeamProfileCard key={team._id} team={team} />
          </Link>
        ))
      ) : (
        <div className="flex items-center justify-center h-96">
          <p className="text-lg font-bold">No Teams</p>
        </div>
      )}
    </div>
  );
}
