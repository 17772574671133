import React from "react";
import tournamentLogo from "../../assets/tournamentLogo.png";
import tournamentCover from "../../assets/tournamentCover.png";
import { Link, useParams } from "react-router-dom";

export default function Card({ data,isMyMatch,isMyTournament }) {
  let startDate = new Date(data?.startDate);
  let endDate = new Date(data?.endDate);

  const months = [
    "JANURAY",
    "FEBURARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  function toHours(milliseconds) {
    return milliseconds / 1000 / 60 / 60;
  }

  let startHour = startDate.getHours();
  let startMinutes = startDate.getMinutes();
  if (startHour < 10) startHour = `0${startHour}`;
  if (startMinutes < 10) startMinutes = `0${startMinutes}`;
  const { gameType } = useParams();
  return (
    <Link
      to={isMyTournament ? `/tournaments/${gameType}/${data?._id}?my=true` : `/tournaments/${gameType}/${data?._id}`}
      className="px-4 py-2 rounded-[12px] border border-[#E6E6E6] mx-7 flex flex-col gap-3"
    >
      <div className="w-full flex items-center md:flex-row flex-col justify-between gap-4 text-xs p-2 border-b border-[#E4E4E4]">
        <div className="w-full flex items-center justify-start gap-10">
          <p className="font-bold">
            START DATE -{" "}
            <span className="font-normal">
              {startDate.getDate()} {months[startDate.getMonth()]}{" "}
              {startDate.getFullYear()}
            </span>
          </p>
          <p className="font-bold">
            END DATE -{" "}
            <span className="font-normal">
              {endDate.getDate()} {months[endDate.getMonth()]}{" "}
              {endDate.getFullYear()}
            </span>
          </p>
        </div>
        <div className="w-full flex items-center justify-end gap-10">
          <p className="font-bold text-[#06FF00]">LIVE 0</p>
          <p className="font-bold">
            TIME -{" "}
            <span className="font-normal">
              {startHour}:{startMinutes} HRS
            </span>
          </p>
        </div>
      </div>

      <h1 className="text-center text-sm font-bold uppercase">
        {data?.format ?? ""}
      </h1>

      <div className="relative">
        {/* Cover */}
        <div>
          <div className="w-full h-40 rounded-xl bg-zinc-300 flex justify-center items-center p-3 relative overflow-hidden">
            <div>
              <img
                src={data?.coverImage ?? tournamentCover}
                alt="Selected Logo"
                className="w-full h-full object-cover absolute top-0 left-0"
              />
            </div>
          </div>
        </div>

        {/* Logo */}
        <div>
          <div className="border-2 border-white min-w-40 w-[20%] h-40 rounded-xl bg-zinc-300 flex justify-center items-center p-3 absolute top-[40%] left-8 md:left-[10%]">
            <div>
              <img
                src={data?.tournamentLogo ?? tournamentLogo}
                alt="Selected Logo"
                className="w-full h-full object-cover absolute top-0 left-0 rounded-xl"
              />
            </div>
          </div>
        </div>
      </div>
      <h1 className="text-right xl:text-center font-bold text-xl py-5 md:py-1">
        {data?.title ?? ""}
      </h1>

      <div className="w-full flex items-center justify-center text-sm gap-10 mt-10">
        <p className="font-bold">
          LOCATION -{" "}
          <span className="font-normal uppercase">
            {data?.venues &&
              data?.venues.map((item, index) => (
                <span
                  className="border-r border-zinc-700 pr-2 pl-2 last:border-none"
                  key={index}
                >
                  {item}
                </span>
              ))}
          </span>
        </p>
      </div>
    </Link>
  );
}
