import React from "react";
import teamA from "../../assets/teamA.svg";
import teamB from "../../assets/teamB.svg";

const LastFiveMatches = () => {
  const gameData = [
    {
      date: "12/3/23",
      time: "5:30 am",
      opponent: "Griz",
      result: "102-100",
      win: true,
    },
    {
      date: "12/5/23",
      time: "6:00 pm",
      opponent: "Raptors",
      result: "95-110",
      win: false,
    },
    {
      date: "12/5/23",
      time: "6:00 pm",
      opponent: "Raptors",
      result: "95-110",
      win: false,
    },
    {
      date: "12/5/23",
      time: "6:00 pm",
      opponent: "Raptors",
      result: "95-110",
      win: false,
    },
    {
      date: "12/5/23",
      time: "6:00 pm",
      opponent: "Raptors",
      result: "95-110",
      win: false,
    },
  ];
  return (
    <div className="w-full h-full col-span-4 flex flex-col font-mont items-center justify-center gap-4 rounded-[12px]">
      <div className="w-full flex items-center justify-start p-2">
        <p className="font-bold uppercase text-base">Last 5 Matches</p>
      </div>
      <div className="w-full flex items-center justify-center lg:flex-row flex-col gap-12">
        <div className="w-full flex items-center justify-center flex-col  border border-[#e6e6e6] p-4 rounded-[12px]">
          <div className="flex items-center justify-start w-full py-2 gap-6 border-b border-[#e6e6e6]">
            <img src={teamA} alt="teamA" className="w-6 h-6 object-cover" />
            <p className="font-medium">Team A</p>
          </div>
          <div className="w-full flex items-center justify-start">
            <div className="w-full mx-auto flex items-start justify-start overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-start border-b">Date</th>
                    <th className="py-2 px-4 text-start border-b">Time</th>
                    <th className="py-2 px-4 text-start border-b">Opponent</th>
                    <th className="py-2 px-4 text-start border-b">Result</th>
                    <th className="py-2 px-4 text-start border-b"></th>
                  </tr>
                </thead>
                <tbody>
                  {gameData.map((game, index) => (
                    <tr key={index}>
                      <td className="py-2 px-4 border-b">{game.date}</td>
                      <td className="py-2 px-4 border-b">{game.time}</td>
                      <td className="py-2 px-4 font-bold border-b">
                        {game.opponent}
                      </td>
                      <td className={`py-2 px-4 border-b`}>{game.result}</td>
                      <td
                        className={`py-2 px-4 border-b ${
                          game.win ? "text-green-500" : "text-red-500"
                        }`}
                      >
                        {game.win ? "W" : "L"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center flex-col  border border-[#e6e6e6] p-4 rounded-[12px]">
          <div className="flex items-center justify-start w-full py-2 gap-6 border-b border-[#e6e6e6]">
            <img src={teamB} alt="teamB" className="w-6 h-6 object-cover" />
            <p className="font-medium">Team B</p>
          </div>
          <div className="w-full flex items-center justify-start">
            <div className="w-full mx-auto flex items-start justify-start overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-start border-b">Date</th>
                    <th className="py-2 px-4 text-start border-b">Time</th>
                    <th className="py-2 px-4 text-start border-b">Opponent</th>
                    <th className="py-2 px-4 text-start border-b">Result</th>
                    <th className="py-2 px-4 text-start border-b"></th>
                  </tr>
                </thead>
                <tbody>
                  {gameData.map((game, index) => (
                    <tr key={index}>
                      <td className="py-2 px-4 border-b">{game.date}</td>
                      <td className="py-2 px-4 border-b">{game.time}</td>
                      <td className="py-2 px-4 font-bold border-b">
                        {game.opponent}
                      </td>
                      <td className={`py-2 px-4 border-b`}>{game.result}</td>
                      <td
                        className={`py-2 px-4 border-b ${
                          game.win ? "text-green-500" : "text-red-500"
                        }`}
                      >
                        {game.win ? "W" : "L"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastFiveMatches;
