import React from "react";
import teamA from "../../assets/teamA.svg";
import teamB from "../../assets/teamB.svg";

const TopPlayer = () => {
  return (
    <div className="w-full h-full col-span-4 flex flex-col font-mont items-center justify-center gap-4 rounded-[12px]">
      <div className="w-full flex items-center justify-start p-2">
        <p className="font-bold uppercase text-base">Top Players</p>
      </div>
      <div className="w-full flex items-center justify-center lg:flex-row flex-col gap-12">
        <div className="w-full flex items-center justify-center flex-col border border-[#e6e6e6] p-4 rounded-[12px]">
          <div className="flex items-center justify-start w-full gap-6 border-b py-2 border-[#e6e6e6]">
            <img src={teamA} alt="teamA" className="w-6 h-6 object-cover" />
            <p className="font-medium">Team A</p>
          </div>
          <div className="w-full flex items-center justify-start p-2 border-b border-[#e6e6e6]">
            <p className="font-bold uppercase text-sm text-app-orange">
              Points
            </p>
          </div>
          <div className="flex w-full items-center justify-center gap-8 p-2 border-b border-[#e4e4e4]">
            <div className="flex  items-center justify-center">
              {" "}
              <img src={teamA} alt="" className="w-12 h-12 rounded-full" />
            </div>
            <div className="flex items-center w-full justify-center flex-col gap-2">
              <div className="flex items-center w-full justify-start gap-12">
                <p className="text-sm font-bold">NAME</p>
                <p className="text-sm">TEAM A</p>
              </div>
              <div className="flex items-center w-full text-sm justify-between gap-3">
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>PTS</p>
                  <p>20</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>2PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>3PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>FT</p>
                  <p>7/9</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-start p-2 border-b border-[#e6e6e6]">
            <p className="font-bold uppercase text-sm text-app-orange">
              Rebound
            </p>
          </div>
          <div className="flex w-full items-center justify-center gap-8 p-2 border-b border-[#e4e4e4]">
            <div className="flex  items-center justify-center">
              {" "}
              <img src={teamA} alt="" className="w-12 h-12 rounded-full" />
            </div>
            <div className="flex items-center w-full justify-center flex-col gap-2">
              <div className="flex items-center w-full justify-start gap-12">
                <p className="text-sm font-bold">NAME</p>
                <p className="text-sm">TEAM A</p>
              </div>
              <div className="flex items-center w-full text-sm justify-between gap-3">
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>PTS</p>
                  <p>20</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>2PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>3PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>FT</p>
                  <p>7/9</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-start p-2 border-b border-[#e6e6e6]">
            <p className="font-bold uppercase text-sm text-app-orange">
              Assists
            </p>
          </div>
          <div className="flex w-full items-center justify-center gap-8 p-2">
            <div className="flex  items-center justify-center">
              {" "}
              <img src={teamA} alt="" className="w-12 h-12 rounded-full" />
            </div>
            <div className="flex items-center w-full justify-center flex-col gap-2">
              <div className="flex items-center w-full justify-start gap-12">
                <p className="text-sm font-bold">NAME</p>
                <p className="text-sm">TEAM A</p>
              </div>
              <div className="flex items-center w-full text-sm justify-between gap-3">
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>PTS</p>
                  <p>20</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>2PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>3PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>FT</p>
                  <p>7/9</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center flex-col  border border-[#e6e6e6] p-4 rounded-[12px]">
          <div className="flex items-center justify-start w-full py-2 gap-6 border-b border-[#e6e6e6]">
            <img src={teamB} alt="teamB" className="w-6 h-6 object-cover" />
            <p className="font-medium">Team B</p>
          </div>
          <div className="w-full flex items-center justify-start p-2 border-b border-[#e6e6e6]">
            <p className="font-bold uppercase text-sm text-app-orange">
              Points
            </p>
          </div>
          <div className="flex w-full items-center justify-center gap-8 p-2 border-b border-[#e4e4e4]">
            <div className="flex  items-center justify-center">
              {" "}
              <img src={teamB} alt="" className="w-12 h-12 rounded-full" />
            </div>
            <div className="flex items-center w-full justify-center flex-col gap-2">
              <div className="flex items-center w-full justify-start gap-12">
                <p className="text-sm font-bold">NAME</p>
                <p className="text-sm">TEAM A</p>
              </div>
              <div className="flex items-center w-full text-sm justify-between gap-3">
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>PTS</p>
                  <p>20</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>2PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>3PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>FT</p>
                  <p>7/9</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-start p-2 border-b border-[#e6e6e6]">
            <p className="font-bold uppercase text-sm text-app-orange">
              Rebound
            </p>
          </div>
          <div className="flex w-full items-center justify-center gap-8 p-2 border-b border-[#e4e4e4]">
            <div className="flex  items-center justify-center">
              {" "}
              <img src={teamB} alt="" className="w-12 h-12 rounded-full" />
            </div>
            <div className="flex items-center w-full justify-center flex-col gap-2">
              <div className="flex items-center w-full justify-start gap-12">
                <p className="text-sm font-bold">NAME</p>
                <p className="text-sm">TEAM A</p>
              </div>
              <div className="flex items-center w-full text-sm justify-between gap-3">
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>PTS</p>
                  <p>20</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>2PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>3PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>FT</p>
                  <p>7/9</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-start p-2 border-b border-[#e6e6e6]">
            <p className="font-bold uppercase text-sm text-app-orange">
              Assists
            </p>
          </div>
          <div className="flex w-full items-center justify-center gap-8 p-2">
            <div className="flex  items-center justify-center">
              {" "}
              <img src={teamB} alt="" className="w-12 h-12 rounded-full" />
            </div>
            <div className="flex items-center w-full justify-center flex-col gap-2">
              <div className="flex items-center w-full justify-start gap-12">
                <p className="text-sm font-bold">NAME</p>
                <p className="text-sm">TEAM A</p>
              </div>
              <div className="flex items-center w-full text-sm justify-between gap-3">
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>PTS</p>
                  <p>20</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>2PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>3PTS</p>
                  <p>9/10</p>
                </div>
                <div className="flex items-center justify-center flex-col gap-1">
                  <p>FT</p>
                  <p>7/9</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPlayer;
