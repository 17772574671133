import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axiosInstance from "../axiosInstance";
import { getCurrentMatch } from "../redux/actions/auth";
import CreateableDropDown from "./DropDown/CreateableDropDown";

const Input = ({
  value,
  id,
  name,
  handleChange,
  placeholder,
  label,
  type,
  isError,
  error,
  ...otherProps
}) => {
  return (
    <div className="flex flex-col w-full space-y-1">
      <label className="text-black font-light text-sm">{label}</label>
      <input
        id={id}
        name={name}
        className="w-full px-3 py-2 border rounded-lg border-gray-400"
        type={type ?? "text"}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        {...otherProps}
      />
      {isError && <p className="text-red-500 ml-4">{error}</p>}
    </div>
  );
};

const padNumber = (number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const EditMatchModal = ({ props, hideModal }) => {
  // console.log("props ", props);
  const dispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const initialValues = {
    teamAName: props.matchDetail.teamA.name || "",
    teamBName: props.matchDetail.teamB.name || "",
    tableReferee: props.matchDetail.tableReferee || "",
    floorReferee1: props.matchDetail.floorReferee1 || "",
    floorReferee2: props.matchDetail.floorReferee2 || "",
    floorReferee3: props.matchDetail.floorReferee3 || "",
    venue: props.matchDetail.venue || "",
    city: props.matchDetail.city || "",
    dateAndTime:
      new Date(props.matchDetail.dateAndTime).toISOString().slice(0, 19) || "",
    threeByThree: props.matchDetail.threeByThree || false,
    matchType: props.matchDetail.matchType || "",
    youtubeLink: props.youtubeLink || "",
  };
  const getTeams = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get("/team/all");
      const obj = res.data?.data?.map((team) => {
        return {
          label: team.name,
          value: team.name,
          _id: team._id,
        };
      });
      setTeams(obj);
    } catch (error) {
      toast.error("Could not fetch existing teams.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTeams();
  }, []);
  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: Yup.object({
      teamAName: Yup.string().required("Required"),
      teamBName: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      venue: Yup.string().required("Required"),
      floorReferee1: Yup.string().required("Required"),
      tableReferee: Yup.string().required("Required"),
      dateAndTime: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      // // console.log(values);
      // dispatch(
      //     editMatch(
      //         values.tableReferee,
      //         values.floorReferee1,
      //         values.floorReferee2,
      //         values.floorReferee3,
      //         values.venue,
      //         values.city,
      //         values.dateAndTime,
      //         values.teamAName,
      //         values.teamBName,
      //         values.threeByThree,
      //         props.matchDetail._id,
      //         setLoading,
      //         queryClient,
      //         hideModal
      //     )
      // );
      try {
        const res = await axiosInstance.patch(
          "/match/update/" + props.matchDetail._id,
          {
            tableReferee: values.tableReferee,
            floorReferee1: values.floorReferee1,
            floorReferee2: values.floorReferee2,
            floorReferee3: values.floorReferee3,
            venue: values.venue,
            city: values.city,
            dateAndTime: values.dateAndTime,
            teamAName: values.teamAName,
            teamBName: values.teamBName,
            threeByThree: values.threeByThree,
            youtubeLink: values.youtubeLink,
          }
        );
        // console.log(res);
        hideModal();
        queryClient.invalidateQueries("getMatch");
        dispatch(getCurrentMatch(props.matchDetail._id, setLoading));
        toast.success("Match Updated Successfully");
      } catch (error) {
        // console.log(error);
      }
    },
  });
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col space-y-3 items-center justify-center"
    >
      {isLoading && (
        <CreateableDropDown
          id={"teamAName"}
          name={"teamAName"}
          placeholder={"Enter First Team Name"}
          options={teams}
          handleChange={(newValue) => {
            // console.log(newValue);
            formik.setFieldValue("teamAName", newValue?.value);
          }}
          isError={formik.touched.teamAName && formik.errors.teamAName}
          error={formik.errors.teamAName}
          isLoading={isLoading}
          value={formik.values.teamAName}
        />
      )}
      {!isLoading && (
        <CreateableDropDown
          id={"teamAName"}
          name={"teamAName"}
          placeholder={"Enter First Team Name"}
          options={teams}
          handleChange={(newValue) => {
            // console.log(newValue);
            formik.setFieldValue("teamAName", newValue?.value);
          }}
          isError={formik.touched.teamAName && formik.errors.teamAName}
          error={formik.errors.teamAName}
          isLoading={isLoading}
          value={formik.values.teamAName}
        />
      )}
      {isLoading && (
        <CreateableDropDown
          id={"teamBName"}
          name={"teamBName"}
          placeholder={"Enter Second Team Name"}
          options={teams}
          handleChange={(newValue) => {
            formik.setFieldValue("teamBName", newValue?.value);
          }}
          isError={formik.touched.teamBName && formik.errors.teamBName}
          error={formik.errors.teamBName}
          isLoading={isLoading}
          value={formik.values.teamBName}
        />
      )}
      {!isLoading && (
        <CreateableDropDown
          id={"teamBName"}
          name={"teamBName"}
          placeholder={"Enter Second Team Name"}
          options={teams}
          handleChange={(newValue) => {
            formik.setFieldValue("teamBName", newValue?.value);
          }}
          isError={formik.touched.teamBName && formik.errors.teamBName}
          error={formik.errors.teamBName}
          isLoading={isLoading}
          value={formik.values.teamBName}
        />
      )}
      <Input
        type="text"
        name="tableReferee"
        value={formik.values.tableReferee}
        placeholder="Table Referee"
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isError={formik.touched.tableReferee && formik.errors.tableReferee}
        error={formik.errors.tableReferee}
      />
      <Input
        type="text"
        name="floorReferee1"
        value={formik.values.floorReferee1}
        placeholder="Floor Referee 1"
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isError={formik.touched.floorReferee1 && formik.errors.floorReferee1}
        error={formik.errors.floorReferee1}
      />
      <Input
        type="text"
        name="floorReferee2"
        value={formik.values.floorReferee2}
        placeholder="Floor Referee 2"
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isError={formik.touched.floorReferee2 && formik.errors.floorReferee2}
        error={formik.errors.floorReferee2}
      />

      <Input
        type="text"
        name="floorReferee3"
        value={formik.values.floorReferee3}
        placeholder="Floor Referee 3"
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isError={formik.touched.floorReferee3 && formik.errors.floorReferee3}
        error={formik.errors.floorReferee3}
      />
      <Input
        type="text"
        name="city"
        value={formik.values.city}
        placeholder="City"
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isError={formik.touched.city && formik.errors.city}
        error={formik.errors.city}
      />
      <Input
        type="text"
        name="venue"
        value={formik.values.venue}
        placeholder="Venue"
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isError={formik.touched.venue && formik.errors.venue}
        error={formik.errors.venue}
      />
      <Input
        type="datetime-local"
        name="dateAndTime"
        value={formik.values.dateAndTime}
        placeholder="Time"
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isError={formik.touched.dateAndTime && formik.errors.dateAndTime}
        error={formik.errors.dateAndTime}
      />
      <Input
        type="text"
        name="youtubeLink"
        value={formik.values.youtubeLink}
        placeholder="Time"
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isError={formik.touched.youtubeLink && formik.errors.youtubeLink}
        error={formik.errors.youtubeLink}
      />
      <div className="flex space-x-4">
        <button
          onClick={() => {
            hideModal();
          }}
          className="bg-gray-500 px-3 py-1 rounded-lg text-white text-lg"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-green-500 px-3 py-1 rounded-lg text-white text-lg"
        >
          Save Changes
        </button>
      </div>
    </form>
  );
};
