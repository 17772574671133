import { useState } from "react"

const Input = ({ value, onChange, placeholder, label, type }) => {
  return (
    <div className="flex flex-col w-full space-y-2">
      <label className="text-black font-light text-sm">{label}</label>
      <input className="w-full px-3 py-2 border rounded-lg border-gray-400" type={type ?? "text"} value={value} onChange={(e) => {
        onChange(e.target.value)
      }} placeholder={placeholder} />
    </div>
  )
}

function padNumber(number) {
  if (number < 10) {
    return "0" + number;
  } else {
    return number.toString();
  }
}

export default function EditPlayerModal({ props, hideModal }) {
  const [playerName, setPlayerName] = useState(props.player.name)
  const [playerJerseyNum, setPlayerJerseyNum] = useState(props.player._id.jerseyNumber)
  const [playerGender, setPlayerGender] = useState(props.player._id.gender)
  const [playerDob, setPlayerDob] = useState(new Date(props.player._id.dateOfBirth))
  const [playerMobile, setPlayerMobile] = useState(props.player._id.mobileNumber)

  // console.log("dob", playerDob.toLocaleDateString())
  // console.log(playerMobile)

  return (
    <form className="flex flex-col space-y-4 items-center justify-center">
      <Input label={"Player Name"} value={playerName} onChange={setPlayerName} placeholder={"Player Name"} />
      <Input label={"Player Jersey Number"} value={playerJerseyNum} onChange={setPlayerJerseyNum} placeholder={"Player Jersey Number"} />
      <div className="flex flex-col w-full space-y-2">
        <label className="text-black font-light text-sm">Gender</label>
        <select className="w-full px-3 py-2 border rounded-lg border-gray-400" onChange={(e) => {
          setPlayerGender(e.target.value)
        }} value={playerGender}>
          <option value="MALE">Male</option>
          <option value="FEMALE">Female</option>
          <option value="OTHERS">Others</option>
        </select>
      </div>
      <Input label={"Date of Birth"} value={`${playerDob.getFullYear()}-${padNumber(playerDob.getMonth())}-${padNumber(playerDob.getDate())}`} defaultValue={playerDob} onChange={setPlayerDob} placeholder={"Date of Birth"} type={"date"} />
      <Input label={"Mobile Number"} value={playerMobile} onChange={setPlayerMobile} placeholder={"Mobile Number"} />
      <div className="flex space-x-4">
        <button onClick={() => {
          hideModal()
        }} className="bg-gray-500 px-3 py-1 rounded-lg text-white text-lg">Cancel</button>
        <button onClick={() => {
          // Make a request to delete the Player
        }} className="bg-green-500 px-3 py-1 rounded-lg text-white text-lg">Edit Player</button>
      </div>
    </form>
  )
}
