import React from "react";
import CreatableSelect from "react-select/creatable";


const CreateableDropDown = ({options,value,id,name,handleChange,handleInputChange,placeholder,handleBlur,isError,error,isLoading}) => {
    return (
        <div className="mb-2 w-full">
            <CreatableSelect
                isClearable
                options={options}
                id={id}
                name={name}
                onChange={handleChange}
                placeholder={placeholder}
                onBlur={handleBlur}
                isLoading={isLoading}
                defaultValue={isLoading ? null : options.map((option) => option.value === value ? option : null)}   
                onInputChange={handleInputChange}
            />
            {isError && <p className="text-red-500 ml-4">{error}</p>}
        </div>
    );
};

export default CreateableDropDown;
