export const options = {
  filter1: ["PLAYERS", "TEAMS"],
  filter2: [
    "POINTS PER GAME",
    "TOTAL POINTS",
    "2PTS FIELD GOALS",
    "3PTS FIELD GOALS",
    "FREE THROW",
    "MINUTES",
  ],
  filter3: ["ALL POSITIONS"],
  filter4: [
    "OVERALL",
    "GROUP STAGE",
    "KNOCKOUT",
    "QUATER FINALS",
    "SEMI FINALS",
    "FINALS",
  ],
};

export const headings = {
  players: [
    "#JRSY",
    "PLAYERS",
    "GP",
    "MPG",
    "PPG",
    "PTS",
    "2PTS",
    "3PTS",
    "FGM",
    "FTM",
  ],
  teams: [
    "#JRSY",
    "PLAYERS",
    "GP",
    "MPG",
    "PPG",
    "PTS",
    "2PTS",
    "3PTS",
    "FGM",
    "FTM",
  ],
};
