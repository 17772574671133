import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function GameCategories({ data }) {
  const location = useLocation();
  return (
    <div className="w-screen py-1 bg-white shadow-lg">
      <div className="max-w-screen-xl mx-auto text-white flex justify-center">
        <div className="flex gap-7 items-center font-bold font-mont uppercase text-black text-[12px]">
          {data?.map((item) => (
            <Link
              key={item.title}
              to={item.link}
              className={`py-4 px-2 ${
                location.pathname.includes(item?.title?.toLowerCase()?.trim())
                  ? " text-app-orange"
                  : " text-black hover:text-[#F2994A] transition duration-300"
              } font-bold text-sm font-mont`}
            >
              <p>{item.title}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
