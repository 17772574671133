import { PlayArrow } from "@mui/icons-material";
import PauseIcon from "@mui/icons-material/Pause";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { ERROR, SUCCESS } from "../../extras/constants";
import useGetMatch from "../../hooks/api/useGetMatch";
import { addToast } from "../../redux/actions/toasts";

const ScoreBoardMarks = ({ teamName, totalScore, fouls, timeouts }) => {
  return (
    <div className="flex items-start flex-col justify-center w-full">
      <div className="flex items-end justify-start w-full pt-4">
        <div className="hidden md:flex flex-col items-center justify-center">
          <div className="flex items-center justify-center w-full">
            <p className="text-[10px] font-medium">Timeouts</p>
          </div>
          <div className="flex items-center justify-center px-6 bg-black text-white text-lg">
            {timeouts}
          </div>
        </div>
        <div className="flex flex-col items-start justify-center flex-1 w-full relative">
          <div className="flex items-center justify-center w-full relative">
            <p className="text-xl text-black font-bold absolute -top-7">
              {teamName}
            </p>
          </div>
          <div className="flex items-center justify-center w-full bg-white text-black text-lg">
            {totalScore}
          </div>
        </div>
        <div className="hidden md:flex flex-col items-center justify-center">
          <div className="flex items-center justify-center w-full">
            <p className="text-[10px] font-medium">Fouls</p>
          </div>
          <div className="flex items-center justify-center px-6 bg-black text-white text-lg">
            {fouls}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full">
        <p className="text-[10px] font-medium">Score</p>
      </div>
      <div className="flex md:hidden items-center justify-center gap-1 w-full">
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex items-center justify-center w-full">
            <p className="text-[10px] font-medium">Timeout</p>
          </div>
          <div className="flex items-center justify-center px-2 xs:px-4 sm:px-6 bg-black text-white text-lg w-full">
            {timeouts}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex items-center justify-center w-full">
            <p className="text-[10px] font-medium">Fouls</p>
          </div>
          <div className="flex items-center justify-center px-2 xs:px-4 sm:px-6 bg-black text-white text-lg w-full">
            {fouls}
          </div>
        </div>
      </div>
    </div>
  );
};

const PlayerTable = ({
  restClass,
  serialColClr,
  nameColClr,
  foulColClr,
  selectedClr,
  tableData,
  playerId,
  setPlayerId,
  setPlayerInId,
  playerInId,
  team,
  setTeamName,
  setPlayer,
  handleAddSubstitute,
}) => {
  return (
    <div className={`flex flex-col items-center  p-4 ${restClass}`}>
      <table className="table-auto border-spacing-2 border-separate p-2 bg-white w-full">
        <thead>
          <tr className="">
            <th className={`px-4 py-2 text-center`}>#</th>
            <th className={`px-4 py-2 text-center`}>Name</th>
            <th className={`px-2 py-2 text-center`}>Points</th>
            <th className={`px-2 py-2 text-center`}>Fouls</th>
          </tr>
        </thead>
        <tbody className="">
          {tableData?.map(
            (item) =>
              item?.isActive && (
                <tr
                  className={`cursor-pointer`}
                  onClick={() => {
                    console.log(item._id);
                    setPlayerId(item._id);
                    setTeamName(team);
                    setPlayer(item);
                  }}
                >
                  <td
                    className={`px-4 py-2 text-center ${
                      playerId === item._id && `${selectedClr}`
                    } ${serialColClr}`}
                  >
                    {item.jerseyNumber !== null ? item.jerseyNumber : 0}
                  </td>
                  <td className={`px-3 py-2 text-center text-sm ${nameColClr}`}>
                    {item.name}
                  </td>
                  <td className={`px-3 py-2 text-center ${nameColClr}`}>
                    {item.points}
                  </td>
                  {item.fouls >= 5 ? (
                    <td className={`px-2 py-2 text-center ${foulColClr}`}>
                      {item.fouls}
                    </td>
                  ) : (
                    <td className={`px-2 py-2 text-center ${nameColClr}`}>
                      {item.fouls}
                    </td>
                  )}
                </tr>
              )
          )}
        </tbody>
      </table>
      {/* <div className="flex items-center justify-center w-full">
        <button
          onClick={() => handleAddSubstitute()}
          className="mt-4 bg-app-orange hover:bg-grey-700 text-white font-bold py-2 px-4 w-full"
        >
          Substitute
        </button>
      </div> */}
      <table className="table-auto border-spacing-2 border-separate p-2 bg-white w-full">
        <tbody className="">
          {tableData?.map(
            (item) =>
              item?.isActive === false && (
                <tr
                  className={`cursor-pointer`}
                  onClick={() => {
                    console.log(item._id);
                    setPlayerInId(item._id);
                    setTeamName(team);
                  }}
                >
                  <td
                    className={`px-4 py-2 text-center ${
                      playerInId === item._id && `${selectedClr}`
                    } ${serialColClr}`}
                  >
                    {item.jerseyNumber !== null ? item.jerseyNumber : 0}
                  </td>
                  <td className={`px-3 py-2 text-center text-sm bg-gray-500`}>
                    {item.name}
                  </td>
                  <td className={`px-3 py-2 text-center bg-gray-500`}>
                    {item.points}
                  </td>
                  {item.fouls >= 5 ? (
                    <td className={`px-2 py-2 text-center ${foulColClr}`}>
                      {item.fouls}
                    </td>
                  ) : (
                    <td className={`px-2 py-2 text-center bg-gray-500`}>
                      {item.fouls}
                    </td>
                  )}
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

const ButtonComponent = ({
  text,
  selectedQuarter,
  setSelectedQuarter,
  onClick,
}) => {
  return (
    <button
      className={`font-semibold uppercase text-[10px] xs:text-xs lg:text-sm py-3 md:px-2 px-2 xs:px-3 lg:px-4 w-full  ${
        selectedQuarter === `${text}`
          ? " text-white bg-app-orange"
          : " text-black bg-white"
      } `}
      onClick={() => {
        onClick();
      }}
    >
      {text}
    </button>
  );
};

const ScoringCard = () => {
  const { matchId } = useParams();
  const { dataMatch: matchDetail, isLoadingMatch } = useGetMatch({
    id: matchId,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [scoringType, setScoringType] = useState("scoring");
  const [scoringType2, setScoringType2] = useState("scoring");
  const [playerId, setPlayerId] = useState();
  const [playerInId, setPlayerInId] = useState();
  const [player, setPlayer] = useState(null);
  const [team, setTeamName] = useState();
  const [foulBtnDisable, setFoulBtnDisable] = useState(false);
  const [isUndo, setisUndo] = useState(false);

  const [selectedQuarter, setSelectedQuarter] = useState(
    "Quarter " + matchDetail?.currentQuarter
  );
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(1);
  const [timer, setTimer] = useState({ minutes: 10, seconds: 0 });
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalScore, setTotalScore] = useState({
    teamA: 0,
    teamB: 0,
  });

  useEffect(() => {
    setSelectedQuarter("Quarter " + matchDetail?.currentQuarter);
  }, [matchDetail]);

  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        if (timer.minutes === 0 && timer.seconds === 0) {
          clearInterval(intervalId);
          setIsTimerRunning(false);
        } else if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 });
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 });
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isTimerRunning, timer]);

  const handlePlayPause = () => {
    setIsTimerRunning(!isTimerRunning);
  };

  const handleReset = () => {
    setTimer({ minutes: 10, seconds: 0 });
    setIsTimerRunning(false);
  };
  const handleAddSubstitute = () => {
    setIsLoading(true);
    const matchId = matchDetail._id;

    const data = {
      playerInId,
      playerOutId: playerId,
      matchId,
      gameTime: new Date(),
      team:
        team === matchDetail?.teamA.name
          ? "a"
          : team === matchDetail?.teamB.name
          ? "b"
          : "",
    };
    axiosInstance
      .post(`/match/addSubstitute`, data)
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries("getMatch");
        // console.log(res);
        toast.success(
          "Substitution Added",
          `Substitution Updated Successfully for team ${team}`
        );
        // navigate(`/matchDetail/${matchDetail._id}/update/score`);
        // dispatch(getCurrentMatch(matchDetail._id, setIsLoading));
      })
      .catch((response) => {
        setIsLoading(false);

        // console.log(response);
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Error", "Invalid Data");
              break;
            case 401:
              //   add a toast
              toast.error("Error", "Unauthorized");
              break;
            default:
              toast.error("Error", "Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Error", "Something went wrong");
        }
      });
  };
  const ScoringButton = ({ value, label, disabled }) => {
    return (
      <div
        onClick={() => {
          !disabled && setSelectedPoints(value);
        }}
        className={`w-full px-4 py-4 ${
          disabled ? "text-gray-500 hover:bg-white cursor-not-allowed " : ""
        } ${selectedPoints === value ? "bg-yellow-400 text-white" : ""}`}
      >
        <p className="font-bold text-base">{label}</p>
      </div>
    );
  };
  const handleFoul = () => {
    setIsLoading(true);
    const data = {
      foulCommittedById: playerId,
      matchId: matchDetail._id,
      team:
        team === matchDetail.teamA.name
          ? "a"
          : team === matchDetail.teamB.name
          ? "b"
          : "",
      gameTime: matchDetail.dateAndTime,
    };
    axiosInstance
      .post(`/match/addFoul`, data)
      .then((res) => {
        queryClient.invalidateQueries("getMatch");

        // console.log(res);
        toast.success(`Foul Updated Successfully for team ${team} `);
        navigate(`/scoring/${matchDetail._id}`);
        setSelectedPoints(null);
      })
      .catch((err) => {
        queryClient.invalidateQueries("getMatch");
        const {
          response: {
            data: { error },
            status,
          },
        } = err;
        setIsLoading(false);
        // console.log(err);
        try {
          switch (status) {
            case 400:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            case 401:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            default:
              toast.error("Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
        setIsLoading(false);
      });
  };

  const handleUndo = (event) => {
    setisUndo(true);
    event.matchId = matchId;
    axiosInstance
      .put(`/match/revertEvent`, event)
      .then((res) => {
        queryClient.invalidateQueries("getMatch");

        // console.log(res);
        toast.success(`Event Reverted Successfully!`);
        setSelectedPoints(null);
      })
      .catch((err) => {
        queryClient.invalidateQueries("getMatch");
        const {
          response: {
            data: { error },
            status,
          },
        } = err;
        setIsLoading(false);
        // console.log(err);
        try {
          switch (status) {
            case 400:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            case 401:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            default:
              toast.error("Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
        setIsLoading(false);
      });
  };

  const handleTimeout = () => {
    setIsLoading(true);
    const data = {
      matchId: matchDetail._id,
      team:
        team === matchDetail.teamA.name
          ? "a"
          : team === matchDetail.teamB.name
          ? "b"
          : "",
      gameTime: timer?.minutes + "," + timer?.seconds,
      timeOutTime: timer?.minutes + "," + timer?.seconds,
    };
    axiosInstance
      .post(`/match/timeout`, data)
      .then((res) => {
        queryClient.invalidateQueries("getMatch");

        // console.log(res);
        handlePlayPause();
        toast.success(`Timeout Updated Successfully for team ${team} `);
        navigate(`/scoring/${matchDetail._id}`);
        setSelectedPoints(null);
      })
      .catch((err) => {
        queryClient.invalidateQueries("getMatch");
        const {
          response: {
            data: { error },
            status,
          },
        } = err;
        setIsLoading(false);
        // console.log(err);
        try {
          switch (status) {
            case 400:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            case 401:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            default:
              toast.error("Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
        setIsLoading(false);
      });
  };
  const handleAddScoreWithoutAssist = () => {
    setIsLoading(true);
    const data = {
      scoredId: playerId,
      points: selectedPoints,
      matchId: matchDetail._id,
      team:
        team === matchDetail.teamA.name
          ? "a"
          : team === matchDetail.teamB.name
          ? "b"
          : "",
      gameTime: matchDetail.dateAndTime,
    };
    axiosInstance
      .post(`/match/addScoreWithoutAssist`, data)
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries("getMatch");

        // console.log(res);
        toast.success(`Score Updated Successfully for team ${team} `);
        navigate(`/scoring/${matchDetail._id}`);
        setSelectedPoints(null);
      })
      .catch((err) => {
        queryClient.invalidateQueries("getMatch");
        const {
          response: {
            data: { error },
            status,
          },
        } = err;

        setIsLoading(false);

        // console.log(err);
        try {
          switch (status) {
            case 400:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            case 401:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: error,
                })
              );
              break;
            default:
              toast.error("Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
      });
  };

  const handleFoulBtn = () => {
    if (player?.fouls >= 5) {
      setFoulBtnDisable(true);
    } else {
      setFoulBtnDisable(false);
    }
  };
  const changeQuarter = (value) => {
    setIsLoading(true);
    axiosInstance
      .post(`/match/changeQuarter`, {
        matchId: matchDetail?._id,
        quarter: parseInt(value),
      })
      .then((res) => {
        setIsLoading(false);

        queryClient.invalidateQueries("getMatch");
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: `Quarter Changed to Q${value}`,
          })
        );
        setSelectedQuarter("Quarter " + value);
      })
      .catch((err) => {
        setIsLoading(false);

        const { response } = err;
        // console.log(response);
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Error", "Invalid Data");
              break;
            case 401:
              //   add a toast
              toast.error("Error", "Unauthorized");
              break;
            default:
              toast.error("Error", "Something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Error", "Something went wrong");
        }
      });
  };
  const getQuarterScores = async () => {
    const { data } = await axiosInstance.get(
      `/match/getQuarterScore/${matchId}`
    );
    const { teamA, teamB } = data;
    let teamAScore = Object.values(teamA);
    teamAScore = teamAScore.reduce((acc, score) => acc + score, 0);
    // console.log(teamAScore);
    let teamBScore = Object.values(teamB);
    teamBScore = teamBScore.reduce((acc, score) => acc + score, 0);
    // console.log(teamAScore, teamBScore);
    setTotalScore({
      teamA: teamAScore,
      teamB: teamBScore,
    });
    return data;
  };
  const { data: quarterScores, error } = useQuery(
    ["getMatch", "getQuarter", matchId],
    getQuarterScores
  );

  useEffect(() => {
    handleFoulBtn();
  }, [player]);

  function countTimeoutEvents(events, team) {
    let timeoutCount = 0;

    for (let i = 0; i < events.length; i++) {
      if (events[i].eventType === "timeout" && events[i].team === team) {
        timeoutCount++;
      }
    }

    return timeoutCount;
  }

  const handleEndMatch = () => {
    if (window.confirm("Are you sure you want to end this match?")) {
      setIsLoading(true);
      axiosInstance
        .post(`/match/finishMatch`, {
          matchId: matchId,
        })
        .then((response) => {
          setIsLoading(false);

          // console.log("end match", response.data);
          queryClient.invalidateQueries("matchesList");
          queryClient.invalidateQueries("getMatch");
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Match Ended Successfully!",
            })
          );
          navigate("/matches/basketball");
        })
        .catch((err) => {
          setIsLoading(false);

          // console.log(" err end match", err);
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Unable End Match!",
            })
          );
        });
    }
  };
  return (
    <section className="w-full h-full flex items-center font-mont justify-center gap-4">
      <div className="flex items-center justify-center w-full">
        <div className="flex items-center justify-center flex-col w-full">
          <div className="flex items-center justify-center w-full bg-[#d61d1d]">
            <div
              onClick={() => navigate("/matches/basketball")}
              className="cursor-pointer -rotate-90 w-[3rem] uppercase text-white font-semibold text-xs"
            >
              HOME
            </div>
            <div className="flex items-center justify-center px-4 sm:px-6 py-4 w-full gap-4 bg-app-orange">
              <div className="flex items-center justify-center max-w-6xl mx-auto w-full gap-3 md:gap-6 md:flex-row flex-col">
                <div className="flex items-center justify-center gap-6 sm:gap-8 md:gap-12 lg:gap-24 w-full">
                  <ScoreBoardMarks
                    teamName={matchDetail?.teamA?.name}
                    totalScore={totalScore?.teamA}
                    fouls={matchDetail?.teamA?.fouls}
                    timeouts={
                      matchDetail?.events
                        ? countTimeoutEvents(matchDetail?.events, "a")
                        : "0"
                    }
                  />
                  <div className="flex items-center flex-row justify-center  bg-white p-1">
                    <div className="flex items-center flex-col justify-center w-[80%]">
                      <div className="flex items-center justify-center gap-4 p-2">
                        <div
                          onClick={handlePlayPause}
                          className="flex items-center justify-center bg-black p-2"
                        >
                          {isTimerRunning ? (
                            <PauseIcon className="text-app-orange" />
                          ) : (
                            <PlayArrow className="text-app-orange" />
                          )}
                        </div>
                        <div
                          onClick={handleReset}
                          className="flex items-center justify-center bg-black p-2"
                        >
                          <RotateRightIcon className="text-red-600" />
                        </div>
                      </div>
                      <p className="flex items-center justify-center font-bold text-2xl">
                        {`${timer.minutes}:${
                          timer.seconds < 10
                            ? `0${timer.seconds}`
                            : timer.seconds
                        }`}
                      </p>
                    </div>
                    <div className="flex w-[20%] items-center text-center whitespace-nowrap -rotate-90 justify-center px-6 bg-black font-bold text-white text-sm p-1">
                      QT {matchDetail?.currentQuarter}
                    </div>
                  </div>
                  <ScoreBoardMarks
                    teamName={matchDetail?.teamB.name}
                    totalScore={totalScore?.teamB}
                    fouls={matchDetail?.teamB?.fouls}
                    timeouts={
                      matchDetail?.events
                        ? countTimeoutEvents(matchDetail?.events, "b")
                        : "0"
                    }
                  />
                </div>
              </div>
            </div>
            <div
              onClick={() => handleEndMatch()}
              className="cursor-pointer w-[3rem] -rotate-90 uppercase text-center text-white font-semibold text-xs"
            >
              end match
            </div>
          </div>
          <div className="w-full bg-black flex flex-col items-center justify-center">
            <div className="w-full grid grid-cols-9 gap-6 items-start justify-start">
              <PlayerTable
                restClass={"lg:flex hidden  col-span-2"}
                serialColClr={"bg-[#B4FF83]"}
                nameColClr={"bg-[#B4FF83]"}
                foulColClr={"bg-[#D61D1D]"}
                selectedClr={"bg-app-orange"}
                playerId={playerId}
                setPlayerId={setPlayerId}
                tableData={matchDetail?.teamA.players}
                team={matchDetail?.teamA.name}
                setTeamName={setTeamName}
                setPlayer={setPlayer}
                handleAddSubstitute={handleAddSubstitute}
                playerInId={playerInId}
                setPlayerInId={setPlayerInId}
              />
              <div className="w-full  flex-1 h-full flex items-center col-span-9 lg:col-span-5 justify-center flex-col bg-black gap-6">
                <div className="w-full flex items-center justify-center bg-white">
                  <div className="w-full flex items-center justify-center xs:p-2">
                    <p
                      onClick={() => {
                        if (scoringType === "scoring") {
                          setScoringType("events");
                        } else {
                          setScoringType("scoring");
                        }
                      }}
                      className="font-semibold text-sm bg-[#cacaca] py-2 px-2 sm:px-8 cursor-pointer"
                    >
                      {scoringType === "scoring" ? "Events" : "Back to Scoring"}
                    </p>
                  </div>
                  <div className="h-full flex items-center justify-between">
                    <button className="flex items-center justify-center rotate-180 border-r px-1 sm:px-3 border-[#cacaca] h-full">
                      <PlayArrow className="hover:text-app-orange" />
                    </button>
                    <div className="h-full flex items-center justify-center font-semibold text-xs xs:text-sm w-full py-2 px-2 xs:px-4 sm:px-8 text-center border-r border-l border-[#cacaca]">
                      Possesion
                    </div>
                    <button className="flex items-center justify-center border-r px-1 sm:px-3 border-[#cacaca] h-full">
                      <PlayArrow className="hover:text-app-orange" />
                    </button>
                  </div>
                  <div className="w-full flex items-center justify-center xs:p-2">
                    <p
                      onClick={() => {
                        if (scoringType === "scoring") {
                          setScoringType("summary");
                        } else {
                          setScoringType("scoring");
                        }
                      }}
                      className="font-semibold text-sm bg-[#cacaca] py-2 px-2 sm:px-8 cursor-pointer"
                    >
                      {scoringType === "scoring"
                        ? "Summary"
                        : "Back to Scoring"}
                    </p>
                  </div>
                </div>
                {scoringType === "scoring" ? (
                  <div className="w-full flex items-center flex-col justify-center bg-white rounded-[2px]">
                    <table className="table-auto w-full">
                      <tbody className="cursor-pointer">
                        <tr className="border-b border-[#cfcfcf]">
                          <td className="w-[33%] h-16  border-r border-[#cfcfcf]  text-center font-bold text-base">
                            <ScoringButton
                              disabled={foulBtnDisable}
                              value={1}
                              label={"+1"}
                            />
                          </td>
                          <td className="w-[33%] h-16 border-r border-[#cfcfcf]  text-center font-bold text-base">
                            <ScoringButton
                              disabled={foulBtnDisable}
                              value={2}
                              label={"+2"}
                            />
                          </td>

                          <td className="w-[33%] h-16   text-center font-semibold text-base">
                            <ScoringButton
                              disabled={foulBtnDisable}
                              value={"foul"}
                              label={"Foul +1"}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="w-[33%] h-16 border-r border-[#cfcfcf] opacity-50   text-center font-bold text-base">
                            <ScoringButton
                              disabled={true}
                              value={-1}
                              label={"-1"}
                            />
                          </td>
                          <td className="w-[33%] h-16 border-r border-[#cfcfcf]  text-center font-bold text-base">
                            <ScoringButton
                              disabled={foulBtnDisable}
                              value={3}
                              label={"+3"}
                            />
                          </td>

                          <td className="w-[33%] h-16  text-center font-semibold text-base">
                            <ScoringButton
                              value={"timeout"}
                              label={"Timeout"}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="w-full flex items-center justify-center pb-2 bg-white px-4">
                      <button
                        disabled={playerId === null || selectedPoints === null}
                        onClick={() => {
                          if (selectedPoints === "foul") {
                            handleFoul();
                          } else if (selectedPoints === "timeout") {
                            handleTimeout();
                          } else {
                            handleAddScoreWithoutAssist();
                          }
                        }}
                        className="uppercase bg-[#FED501] px-4 lg:px-6 py-5 text-black font-bold text-sm w-[70%]"
                      >
                        submit
                      </button>
                      <button
                        onClick={() => setScoringType("events")}
                        className="uppercase bg-[#1A79E5] px-4 lg:px-6 py-5 text-white font-bold text-sm w-[30%]"
                      >
                        UNDO
                      </button>
                    </div>
                  </div>
                ) : scoringType === "events" ? (
                  <div className="w-full overflow-y-scroll h-56 rounded-md drop-shadow-xl bg-white ">
                    <table className="w-full border-collapse">
                      <tr>
                        <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
                          Type
                        </th>
                        <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
                          Event
                        </th>
                        <th className="text-center p-2 border-r-2 border-b-2 boder-[#ddd]">
                          Undo
                        </th>
                      </tr>
                      {matchDetail?.events.length === 0 ? (
                        <tr>
                          <td className="text-center p-2 border-r-2 border-[#ddd] ">
                            -
                          </td>
                          <td>
                            <h1 className="text-center p-2 border-r-2 border-[#ddd] ">
                              No Scores Added Yet. Please Wait.
                            </h1>
                          </td>
                        </tr>
                      ) : (
                        matchDetail?.events
                          .slice(0)
                          .reverse()
                          .map((event) => (
                            <tr>
                              {event.eventType === "score" && (
                                <>
                                  <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                    Score
                                  </td>
                                  <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                    <p className="font-medium text-[#000000B8] w-full text-left">
                                      '{event.scoredBy.name}' from team '
                                      {event.team === "a"
                                        ? matchDetail?.teamA.name
                                        : matchDetail?.teamB.name}
                                      ' scored {event.points} Point(s) in Q
                                      {event.quarter}{" "}
                                      {event.assistedBy &&
                                        " and was assisted by '" +
                                          event.assistedBy.name +
                                          "'"}
                                    </p>
                                  </td>
                                  <td>
                                    <button
                                      className="bg-black text-white px-3 py-2 rounded-lg"
                                      disabled={isUndo}
                                      onClick={() => handleUndo(event)}
                                    >
                                      Undo
                                    </button>
                                  </td>
                                </>
                              )}
                              {event.eventType === "timeout" && (
                                <>
                                  <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                    Timeout
                                  </td>
                                  <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                    <p className="font-medium text-[#000000B8] w-full text-left">
                                      Team '
                                      {event.team === "a"
                                        ? matchDetail?.teamA.name
                                        : matchDetail?.teamB.name}
                                      ' got a timeout with{" "}
                                      {event.timeOutTime.split(",")[0]} minutes
                                      and {event.timeOutTime.split(",")[1]}{" "}
                                      seconds remaining in the game.
                                    </p>
                                  </td>
                                  <td>
                                    <button
                                      className="bg-black text-white px-3 py-2 rounded-lg"
                                      disabled={isUndo}
                                      onClick={() => handleUndo(event)}
                                    >
                                      Undo
                                    </button>
                                  </td>
                                </>
                              )}

                              {event.eventType === "substitute" && (
                                <>
                                  <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                    Substitute
                                  </td>
                                  <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                    <p className="font-medium text-[#000000B8] w-full text-left">
                                      '{event.playerIn.name}' from team '
                                      {event.team === "a"
                                        ? matchDetail?.teamA.name
                                        : matchDetail?.teamB.name}
                                      ' substituted by {event.playerOut.name} in
                                      Q{event.quarter}{" "}
                                    </p>
                                  </td>
                                </>
                              )}
                              {event.eventType === "foul" && (
                                <>
                                  <td className="text-center p-2 border-r-2 border-[#ddd]">
                                    Fouls
                                  </td>
                                  <td className="text-center p-2 border-r-2 border-[#ddd]">
                                    {event.foulCommittedOn ? (
                                      <p className="font-medium text-[#000000B8] w-full text-left">
                                        '{event.foulCommittedBy.name}' from team
                                        '
                                        {event.team === "a"
                                          ? matchDetail?.teamA.name
                                          : matchDetail?.teamB.name}
                                        ' commited a foul on '
                                        {event.foulCommittedOn.name}'' from team
                                        '
                                        {event.team === "a"
                                          ? matchDetail?.teamB.name
                                          : matchDetail?.teamA.name}
                                        ' in Q{event.quarter}{" "}
                                      </p>
                                    ) : (
                                      <p className="font-medium text-[#000000B8] w-full text-left">
                                        '{event.foulCommittedBy.name}' from team
                                        '
                                        {event.team === "a"
                                          ? matchDetail?.teamA.name
                                          : matchDetail?.teamB.name}
                                        ' commited a foul in Q{event.quarter}{" "}
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="bg-black text-white px-3 py-2 rounded-lg"
                                      disabled={isUndo}
                                      onClick={() => handleUndo(event)}
                                    >
                                      Undo
                                    </button>
                                  </td>
                                </>
                              )}
                              {/* <p>{event}</p> */}
                            </tr>
                          ))
                      )}
                    </table>
                  </div>
                ) : (
                  <div
                    className={`${
                      matchDetail?.threeByThree ? "w-1/2" : "w-full"
                    } flex items-center justify-center rounded-l-md drop-shadow-xl bg-[#FFD500] py-5`}
                  >
                    <div className="flex items-end gap-5 lg:gap-x-14">
                      <div>
                        <h1 className="my-2 font-semibold">
                          {matchDetail?.teamA?.name}
                        </h1>
                        <h1 className="my-1 font-semibold">
                          {matchDetail?.teamB?.name}
                        </h1>
                      </div>
                      <div className=" flex lg:gap-14 gap-4 items-center">
                        {[1, 2, 3, 4, 5].map((item) => {
                          // console.log(team)
                          if (matchDetail?.threeByThree && item > 1) return;
                          return (
                            <div
                              className="flex flex-col items-center"
                              key={item}
                            >
                              <h1
                                className={`my-2 font-semibold px-2 ${
                                  matchDetail?.currentQuarter === item
                                    ? "bg-white"
                                    : " "
                                }`}
                              >
                                {item === 5 || matchDetail?.threeByThree
                                  ? "TOT"
                                  : "Q" + item}
                              </h1>
                              <h1 className="my-1 font-semibold">
                                {item === 5
                                  ? totalScore.teamA
                                  : quarterScores?.teamA[item] ?? 0}
                              </h1>
                              <h1 className="my-1 font-semibold">
                                {item === 5
                                  ? totalScore.teamB
                                  : quarterScores?.teamB[item] ?? 0}
                              </h1>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-full flex items-center justify-center bg-white">
                  <ButtonComponent
                    text="Quarter 1"
                    selectedQuarter={selectedQuarter}
                    setSelectedQuarter={setSelectedQuarter}
                    onClick={() => {
                      changeQuarter(1);
                    }}
                  />
                  <div className="rotate-180 h-[22px] w-[1px] bg-[#cfcfcf]"></div>
                  <ButtonComponent
                    text="Quarter 2"
                    selectedQuarter={selectedQuarter}
                    setSelectedQuarter={setSelectedQuarter}
                    onClick={() => {
                      changeQuarter(2);
                    }}
                  />
                  <div className="rotate-180 h-[22px] w-[1px] bg-[#cfcfcf]"></div>
                  <ButtonComponent
                    text="Quarter 3"
                    selectedQuarter={selectedQuarter}
                    setSelectedQuarter={setSelectedQuarter}
                    onClick={() => {
                      changeQuarter(3);
                    }}
                  />
                  <div className="rotate-180 h-[22px] w-[1px] bg-[#cfcfcf]"></div>
                  <ButtonComponent
                    text="Quarter 4"
                    selectedQuarter={selectedQuarter}
                    setSelectedQuarter={setSelectedQuarter}
                    onClick={() => {
                      changeQuarter(4);
                    }}
                  />
                </div>
              </div>
              <PlayerTable
                restClass={"lg:flex hidden col-span-2"}
                serialColClr={"bg-[#FFC177]"}
                nameColClr={"bg-[#FFC177]"}
                foulColClr={"bg-[#D61D1D]"}
                selectedClr={"bg-green-600"}
                playerId={playerId}
                setPlayerId={setPlayerId}
                tableData={matchDetail?.teamB.players}
                team={matchDetail?.teamB.name}
                setTeamName={setTeamName}
                setPlayer={setPlayer}
                handleAddSubstitute={handleAddSubstitute}
                playerInId={playerInId}
                setPlayerInId={setPlayerInId}
              />
            </div>
            <div className="lg:hidden flex items-center justify-center gap-6 w-full sm:flex-row flex-col">
              <PlayerTable
                restClass={"flex w-full"}
                serialColClr={"bg-[#B4FF83]"}
                nameColClr={"bg-[#B4FF83]"}
                foulColClr={"bg-[#D61D1D]"}
                selectedClr={"bg-app-orange"}
                playerId={playerId}
                setPlayerId={setPlayerId}
                tableData={matchDetail?.teamA.players}
                team={matchDetail?.teamA.name}
                setTeamName={setTeamName}
                setPlayer={setPlayer}
                handleAddSubstitute={handleAddSubstitute}
                playerInId={playerInId}
                setPlayerInId={setPlayerInId}
              />
              <PlayerTable
                restClass={"flex w-full"}
                serialColClr={"bg-[#FFC177]"}
                nameColClr={"bg-[#FFC177]"}
                foulColClr={"bg-[#D61D1D]"}
                selectedClr={"bg-[#00FF00]"}
                playerId={playerId}
                setPlayerId={setPlayerId}
                tableData={matchDetail?.teamB.players}
                team={matchDetail?.teamB.name}
                setTeamName={setTeamName}
                setPlayer={setPlayer}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScoringCard;
