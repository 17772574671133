import React, { useEffect, useState } from "react";
import { options, headings } from "./leaderboard_extra";
import Selector from "../../components/DropDown/Selector";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { useMemo } from "react";

export default function Leaderboard() {
  const [filter1, setFilter1] = useState(options.filter1[1]);
  const [filter2, setFilter2] = useState(options.filter2[0]);
  const [filter3, setFilter3] = useState(options.filter3[0]);
  const [filter4, setFilter4] = useState(options.filter4[0]);
  const [heading, setHeading] = useState(headings.players);
  const [tableData, setTableData] = useState({});

  const { id } = useParams();

  const fetchTeamScore = async () => {
    if (filter1 === "TEAMS") {
      const res = await axiosInstance.get(`/tournament/scores/team/${id}`);
      return res?.data?.data;
    } else {
      return null;
    }
  };

  const { data, error, isLoading } = useQuery(
    ["fetchTeamScore"],
    fetchTeamScore
  );

  const filterData = useMemo(() => {
    let data = tableData;
  });

  useEffect(() => {
    fetchTeamScore().then((data) => setTableData(data));
  }, []);

  useEffect(() => {
    if (filter1 === "PLAYERS") {
      setHeading(headings.players);
    } else {
      setHeading(headings.teams);
    }
  }, [filter1]);

  return (
    <div className="flex flex-col gap-10 min-h-[30rem]">
      {/* Filters */}
      <div className="flex flex-wrap gap-2 lg:gap-10 z-10">
        <Selector
          options={options.filter1}
          setFilter={setFilter1}
          filter={filter1}
        />
        <Selector
          options={options.filter2}
          setFilter={setFilter2}
          filter={filter2}
        />
        <Selector
          options={options.filter3}
          setFilter={setFilter3}
          filter={filter3}
        />
        <Selector
          options={options.filter4}
          setFilter={setFilter4}
          filter={filter4}
        />
      </div>

      {/* Table */}
      <div className="overflow-x-auto rounded-xl border">
        <table className="w-full text-left rounded-xl">
          <thead>
            <tr>
              <th className="p-3 bg-app-orange text-[13px] tracking-wide">
                S.No
              </th>
              {heading.map((item, index) => {
                return (
                  <th
                    key={index}
                    className="p-3 bg-app-orange text-[13px] tracking-wide"
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="11" className="p-2 px-4">
                  Loading...
                </td>
              </tr>
            ) : data && Object.keys(data).length == 0 ? (
              <tr>
                <td colSpan="11" className="p-2 px-4">
                  No data found
                </td>
              </tr>
            ) : (
              Object.keys(data) &&
              Object.keys(data).map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="text-sm font-medium even:bg-zinc-50"
                  >
                    <td className="p-2 px-4">{index + 1}</td>
                    <td className="p-2 px-4"> -- </td>
                    <td className="p-2 px-4">{data[item]?.team_name}</td>
                    <td className="p-2 px-4">{data[item]?.played_matches}</td>
                    <td className="p-2 px-4">{data[item]?.wins}</td>
                    <td className="p-2 px-4"> -- </td>
                    <td className="p-2 px-4">{data[item]?.points}</td>
                    <td className="p-2 px-4"> -- </td>
                    <td className="p-2 px-4">{data[item]?.threePointer}</td>
                    <td className="p-2 px-4">{data[item]?.fgm}</td>
                    <td className="p-2 px-4">{data[item]?.ftm}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
