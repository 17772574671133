import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SingleMatchesNav = ({ hidePreview }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="flex items-center justify-center flex-row gap-12 w-full flex-wrap p-6 shadow-lg">
      {!hidePreview ? (
        <div
          onClick={() => navigate("preview")}
          className={`${
            location.pathname.includes("preview")
              ? " text-app-orange transition duration-300"
              : " text-black transition duration-300"
          } uppercase text-xs font-mont font-bold cursor-pointer`}
        >
          PREVIEW
        </div>
      ) : null}
      <div
        onClick={() => navigate("overview")}
        className={`${
          location.pathname.includes("overview")
            ? " text-app-orange transition duration-300"
            : " text-black transition duration-300"
        } uppercase text-xs font-mont font-bold cursor-pointer`}
      >
        Overview
      </div>

      <div
        onClick={() => navigate("playbyplay")}
        className={`${
          location.pathname.includes("playbyplay")
            ? " text-app-orange transition duration-300"
            : " text-black transition duration-300"
        } uppercase text-xs font-mont font-bold cursor-pointer`}
      >
        PLAY-BY-PLAY
      </div>
      <div
        onClick={() => navigate("teamcomparison")}
        className={`${
          location.pathname.includes("teamcomparison")
            ? " text-app-orange transition duration-300"
            : " text-black transition duration-300"
        } uppercase text-xs font-mont font-bold cursor-pointer`}
      >
        Team Comparison
      </div>
      <div
        onClick={() => navigate("boxscore")}
        className={`${
          location.pathname.includes("boxscore")
            ? " text-app-orange transition duration-300"
            : " text-black transition duration-300"
        } uppercase text-xs font-mont font-bold cursor-pointer`}
      >
        Box Score
      </div>
    </div>
  );
};

export default SingleMatchesNav;
