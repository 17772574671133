import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GameBar from "../../components/GameBar";
// import GameCategories from "../../components/GameCategories";
import axiosInstance from "../../axiosInstance";
import MatchesSection from "../../containers/MatchesPage/MatchesSection";
import { gameType } from "../../extras/constants";
import useGetRecentMatch from "../../hooks/api/useGetRecentMatch";

const MatchesPage = () => {
  const location = useLocation();
  let gameBarData = [];
  const [form, setForm] = React.useState({
    date: new Date().toISOString().split("T")[0],
  });
  for (let [key, value] of Object.entries(gameType)) {
    gameBarData.push({
      title: key,
      link: value[0].link,
    });
  }
  const { dataMatch: recentMatches, isLoadingMatch } = useGetRecentMatch({
    date: "",
    status: "",
  });

  let date = form.date;
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance
      .post(`/match/getAllMatches`, {
        date,
      })
      .then((res) => setData(res?.data));
  }, [form.date, date]);

  console.log(data);
  return (
    <main className="flex items-center justify-center w-full flex-col mb-12 min-h-[100dvh]">
      <GameBar data={gameBarData} />
      {/* <GameCategories data={gameType[location.pathname.split("/")[2]]} /> */}
      <MatchesSection
        data={data}
        recentMatches={recentMatches}
        isLoadingMatch={isLoadingMatch}
        form={form}
        setForm={setForm}
      />
      {/* <ArticleSection /> */}
    </main>
  );
};

export default MatchesPage;
