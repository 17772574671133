import React from "react";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

const GameLeader = ({ data }) => {
  const [isActive, setIsActive] = React.useState("points");
  const stats = ["points"];

  const highPtsA = data?.teamA?.players?.reduce((maxPlayer, currentPlayer) => {
    return currentPlayer.points > maxPlayer.points ? currentPlayer : maxPlayer;
  }, data?.teamA?.players[0]);

  const highPtsB = data?.teamB?.players?.reduce((maxPlayer, currentPlayer) => {
    return currentPlayer.points > maxPlayer.points ? currentPlayer : maxPlayer;
  }, data?.teamB?.players[0]);

  return (
    <div className="w-full h-full flex flex-col font-mont items-center col-span-1 justify-center px-4 py-2 rounded-[12px] border border-[#E6E6E6]">
      <div className="w-full flex items-center justify-start p-2 border-b border-[#E4E4E4]">
        <p className="font-bold uppercase text-base">Top Player Stats</p>
      </div>
      <div className="flex items-center justify-start p-4 border-b border-[#e4e4e4] w-full">
        {stats.map((item, index) => (
          <div key={index} className="w-full items-center justify-start flex">
            <button
              onClick={() => setIsActive(item)}
              className={`font-bold uppercase text-sm ${
                isActive === item ? "text-app-orange" : "text-black"
              }`}
            >
              {item}
            </button>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-start p-2 flex-col gap-1 w-full">
        <div className="flex w-full items-center justify-center gap-2 p-2 border-b border-[#e4e4e4]">
          <div className="flex w-full items-center justify-center">
            {" "}
            <ProfilePhoto
              name={data?.teamA?.name ?? "Team A"}
              color="black"
              restClass="w-12 h-12 rounded-full"
            />
          </div>
          <div className="flex items-center w-full justify-center flex-col gap-2">
            <div className="flex items-center w-full justify-start gap-3">
              <p className="text-sm font-bold">{highPtsA?.name}</p>
              <p className="text-sm">{data?.teamA?.name}</p>
            </div>
            <div className="flex items-center w-full text-sm justify-between gap-3">
              <div className="flex items-center justify-center flex-col gap-1">
                <p>PTS</p>
                <p>{highPtsA?.points}</p>
              </div>
              {/* <div className="flex items-center justify-center flex-col gap-1">
                <p>FG</p>
                <p>9/10</p>
              </div>
              <div className="flex items-center justify-center flex-col gap-1">
                <p>FT</p>
                <p>7/9</p>
              </div> */}
            </div>
          </div>
          <div className="flex w-full items-center justify-end">
            <ProfilePhoto
              name={data?.teamA?.name ?? "Team A"}
              color="black"
              restClass="w-8 h-8 rounded-full"
            />
          </div>
        </div>
        <div className="flex w-full items-center justify-center gap-2 p-2">
          <div className="flex w-full items-center justify-center">
            {" "}
            <ProfilePhoto
              name={data?.teamB?.name ?? "Team B"}
              color="black"
              restClass="w-12 h-12 rounded-full"
            />
          </div>
          <div className="flex items-center w-full justify-center flex-col gap-2">
            <div className="flex items-center w-full justify-start gap-3">
              <p className="text-sm font-bold">{highPtsB?.name}</p>
              <p className="text-sm">{data?.teamB?.name}</p>
            </div>
            <div className="flex items-center w-full text-sm justify-between gap-3">
              <div className="flex items-center justify-center flex-col gap-1">
                <p>PTS</p>
                <p>{highPtsB?.points}</p>
              </div>
              {/* <div className="flex items-center justify-center flex-col gap-1">
                <p>FG</p>
                <p>9/10</p>
              </div>
              <div className="flex items-center justify-center flex-col gap-1">
                <p>FT</p>
                <p>7/9</p>
              </div> */}
            </div>
          </div>
          <div className="flex w-full items-center justify-end">
            {" "}
            <ProfilePhoto
              name={data?.teamB?.name ?? "Team B"}
              color="black"
              restClass="w-8 h-8 rounded-full"
            />
          </div>
        </div>
      </div>
      {/* <div className="flex items-center justify-center p-2  w-full">
        <button className="flex items-center justify-center text-xs uppercase">
          View all players Stats
        </button>
      </div> */}
    </div>
  );
};

export default GameLeader;
