import React from "react";
import GameBar from "../../components/GameBar";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import ProfileSection from "./ProfileSection";
// import GameCategories from "../../components/GameCategories";
import TeamSection from "./TeamSection";
import { gameType } from "../../extras/constants";

export default function Profile() {
  const { game } = useParams();
  const location = useLocation();
  let gameBarData = [];
  for (let [key, value] of Object.entries(gameType)) {
    gameBarData.push({
      title: key,
      link: value[0].link,
    });
  }

  return (
    <div className="w-full">
      {/* Navigations */}
      <GameBar data={gameBarData} />
      {/* <GameCategories data={data[location.pathname.split("/")[2]]} /> */}
      <Routes>
        <Route path="/:game/player/*" element={<ProfileSection />} />
        <Route path="/:game/team/:teamId/*" element={<TeamSection />} />
        <Route path="/:playerId/*" element={<ProfileSection />} />
        <Route path="*" element={<Navigate to="basketball/player" />} />
      </Routes>
    </div>
  );
}
