import React from "react";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

export default function PlayerCard({ player }) {
  return (
    <div className="border border-border-gray rounded-2xl p-3 flex flex-col gap-2">
      <h1 className="text-center font-bold text-xl break-words">
        {player?.name}
      </h1>
      <h2 className="text-center">#{player?.jerseyNumber}</h2>
      <div className="flex gap-2 justify-center pb-5">
        {/* <div className="flex flex-col gap-3 flex-1">
          <div className="flex flex-col w-full justify-between items-start text-[15px] tracking-wider">
            <h3 className=" font-bold">COUNTRY</h3>
            <h3>HEIGHT</h3>
          </div>
          <div className="flex flex-col w-full justify-between items-start text-[15px] tracking-wider">
            <h3 className=" font-bold">TEAM</h3>
            <h3>BOSTON CELTICS</h3>
          </div>
        </div> */}

        <ProfilePhoto
          name={player?.name ?? "Player A"}
          color="black"
          restClass="w-28 h-28 rounded-full"
        />

        {/* <div className="flex flex-col gap-3 flex-1">
          <div className="flex flex-col w-full justify-between items-end text-[15px] tracking-wider">
            <h3 className=" font-bold">HEIGHT</h3>
            <h3>6'6</h3>
          </div>
          <div className="flex flex-col w-full justify-between items-end text-[15px] tracking-wider">
            <h3 className=" font-bold">AGE</h3>
            <h3>32</h3>
          </div>
        </div> */}
      </div>
      <div className="bg-black text-white text-[15px] tracking-wider rounded-xl p-3 flex flex-col gap-2 items-center -mt-2">
        <h1 className="font-bold text-xl">THIS SEASON</h1>
        <div className="flex justify-center items-start flex-wrap gap-5 font-semibold">
          <div className="flex flex-col gap-2 items-center">
            <p className="text-[14px]">FT</p>
            <p className="font-bold text-xl">{player?.points?.one}</p>
          </div>
          <div className="flex flex-col gap-2 items-center">
            <p className="text-[14px]">2-POINTER</p>
            <p className="font-bold text-xl">{player?.points?.two}</p>
          </div>
          <div className="flex flex-col gap-2 items-center">
            <p className="text-[14px]">3-POINTER</p>
            <p className="font-bold text-xl">{player?.points?.three}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
