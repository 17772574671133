import React from "react";
import { camelCaseToTitleCase } from "../../extras/utils";

export default function First({ apiResponse, profileStats }) {
  const dob = new Date(apiResponse?.player?.dateOfBirth);
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "DEC",
  ];
  const playerInfo = [
    {
      key: "TEAM",
      value: "--",
    },
    {
      key: "POSITION",
      value: "--",
    },
    {
      key: "HEIGHT",
      value: apiResponse?.player?.height ?? "- -" + " cm",
    },
    {
      key: "WEIGHT",
      value: apiResponse?.player?.weight ?? "- -" + " kg",
    },
    {
      key: "BIRTH DATE",
      value:
        months[dob.getMonth()] + " " + dob.getDate() + " " + dob.getFullYear(),
    },
    {
      key: "COLLEGE",
      value: "--",
    },
    {
      key: "DRAFT INFO",
      value: "--",
    },
    {
      key: "EXPERIENCE",
      value: "--",
    },
  ];
  console.log(profileStats, "PROFILE");
  return (
    <>
      <div className="border border-border-gray rounded-xl">
        <div className="px-7 py-3 font-bold">
          <h1 className="text-xl">{apiResponse?.player?.name}</h1>
        </div>
        <div className="border-t border-border-gray"></div>
        <div className="p-7 grid gap-5 md:grid-cols-4 grid-cols-2">
          {Object.keys(profileStats).map((key) => (
            <div key={key}>
              <p className="text-sm font-bold ">{camelCaseToTitleCase(key)}</p>
              <p className="text-sm text-sec">{profileStats[key]}</p>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="border border-border-gray rounded-xl">
        <div className="px-7 py-3 font-bold">
          <h1 className="text-xl">CAREER HIGHLIGHTS</h1>
        </div>
        <div className="border-t border-border-gray"></div>
        <div className="p-7 grid gap-5 grid-flow-col grid-rows-2 md:grid-cols-4 grid-cols-2">
          <div>
            <p className="text-sm font-bold">TROPHY</p>
            <p className="text-sm text-sec">
              --
            </p>
          </div>
          <div>
            <p className="text-sm font-bold">TROPHY</p>
            <p className="text-sm text-sec">
              --
            </p>
          </div>
          <div>
            <p className="text-sm font-bold">MEDALS</p>
            <p className="text-sm text-sec">
              --
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}
