import React from "react";
import MatchMaking from "../../components/Tournament/MatchMaking";
import StandingGroupTable from "../../components/Tournament/StandingGroupTable";

export default function Standings() {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-5">
        <h1 className="text-lg font-bold uppercase tracking-wide">
          Tournament Standings
        </h1>
        <MatchMaking />
      </div>

      <div className="flex flex-col gap-5">
        <h1 className="text-lg font-bold uppercase tracking-wide">
          Group Stage
        </h1>
        <div className="grid lg:grid-cols-2 w-full gap-7">
          <StandingGroupTable group="A" />
          <StandingGroupTable group="B" />
          <StandingGroupTable group="C" />
          <StandingGroupTable group="D" />
        </div>
      </div>
    </div>
  );
}
