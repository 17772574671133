// BarChartCard.js
import React from "react";
import { Bar } from "react-chartjs-2";
import { BarElement, CategoryScale, Chart, LinearScale } from "chart.js";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels, CategoryScale, LinearScale, BarElement);

const BarChartCard = ({ heading, data }) => {
  const chartData = {
    labels: ["Team A", "Team B"],
    datasets: [
      {
        label: heading,
        backgroundColor: ["#008348", "#5D76A9"],
        borderColor: ["#008348", "#5D76A9"],
        borderWidth: 1,
        hoverBackgroundColor: ["#008348", "#5D76A9"],
        hoverBorderColor: ["#008348", "#5D76A9"],
        data: [data.teamA, data.teamB],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        max: Math.max(Number(data.teamA), Number(data.teamB)) + 20,
        display: false,
      },
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    },

    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: Math.round,
        font: {
          weight: "bold",
        },
      },
    },
  };

  return (
    <div className="w-full border rounded-md p-4 flex flex-col">
      <h2 className="w-full text-sm font-semibold mb-4">{heading}</h2>
      <div className="w-full h-full flex items-center justify-center">
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default BarChartCard;
