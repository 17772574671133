import parse from "html-react-parser";
import React from "react";
import { useNavigate } from "react-router";
import quote from "../assets/quote.svg";
const FooterSec = ({ text }) => {
  const navigate = useNavigate();
  return (
    <section className="w-full flex items-center justify-center bg-app-orange bg-footerbg bg-no-repeat bg-center bg-cover min-h-[100dvh]">
      <div className="flex items-center justify-center p-2">
        <div className="flex items-center justify-center p-2 flex-col gap-8 max-w-3xl">
          <div className="flex items-center justify-center">
            <img src={quote} alt={quote} className="w-[85%]" />
          </div>
          <div className="text-white font-bold text-3xl sm:text-4xl font-mont text-center">
            {parse(text)}
          </div>
          <div>
            <button
              onClick={() => navigate("/createTeam")}
              className="bg-white text-app-orange rounded-full uppercase font-opsans font-bold text-xs px-6 py-3"
            >
              Start Making your team
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterSec;
