import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { deletePlayer } from "../redux/actions/auth";

export default function DeletePlayerModal({ props, hideModal }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  // console.log(props);
  return (
    <div className="flex flex-col space-y-4 items-center justify-center w-full h-full">
      <div className="flex flex-col space-y-2 items-center">
        <h1 className="text-xl">Player Name - {props.player.name}</h1>
        <h1 className="text-xl">
          Player Jersey Number - {props.player._id.jerseyNumber}
        </h1>
        <h1 className="text-xl">Team - {props.team}</h1>
      </div>
      <div className="flex justify-between space-x-4">
        <button
          onClick={() => {
            hideModal();
          }}
          className="bg-gray-500 px-3 py-1 rounded-lg text-white text-lg"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            // console.log("player for deletion ",props.player);
            dispatch(
              deletePlayer(
                props.player._id,
                props.matchId,
                props.team,
                setLoading,
                hideModal,
                queryClient
              )
            );
          }}
          className="bg-red-500 px-3 py-1 rounded-lg text-white text-lg"
        >
          Delete
        </button>
      </div>
    </div>
  );
}
