import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import CreateMatch from "../../containers/Common/CreateMatch";
import Gallery from "../../containers/Tournament/Gallery";
import Games from "../../containers/Tournament/Games";
import Leaderboard from "../../containers/Tournament/Leaderboard";
import Overview from "../../containers/Tournament/Overview";
import Standings from "../../containers/Tournament/Standings";

export default function Nba() {
  const location = useLocation();
  const { gameType, id } = useParams();
  const isMyTournament = location.search.split("=")[1];
  const pageNavs = [
    {
      name: "OVERVIEW",
      route: `/tournaments/${gameType}/${id}${
        isMyTournament ? "?my=true" : ""
      }`,
    },
    {
      name: "GAMES",
      route: `/tournaments/${gameType}/${id}/games${
        isMyTournament ? "?my=true" : ""
      }`,
    },
    {
      name: "STANDINGS",
      route: `/tournaments/${gameType}/${id}/standings${
        isMyTournament ? "?my=true" : ""
      }`,
    },
    {
      name: "LEADERBOARD",
      route: `/tournaments/${gameType}/${id}/leaderboard${
        isMyTournament ? "?my=true" : ""
      }`,
    },
    {
      name: "GALLERY",
      route: `/tournaments/${gameType}/${id}/gallery${
        isMyTournament ? "?my=true" : ""
      }`,
    },
  ];
  const [data, setData] = useState([]);

  const fetchTourData = async () => {
    try {
      const res = await axiosInstance.get(`/tournament/detail/${id}`);
      setData(res?.data?.tournamentDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: All,
    error,
    isLoading,
  } = useQuery(["fetchTourData"], fetchTourData);

  useEffect(() => {
    fetchTourData();
  }, []);

  return (
    <div className="w-full py-10 flex flex-col gap-10">
      <div className="max-w-screen-xl w-full mx-auto flex flex-col gap-5 md:px-0 px-3">
        <h1 className="text-2xl font-bold uppercase">
          {data?.title ?? "NAME OF TOURNAMENT"}
        </h1>
        {/* <Position /> */}
      </div>

      {/* In page navigation */}
      <div className="w-screen  shadow-navBar overflow-hidden">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex gap-7 items-center font-semibold py-4 font-mont text-[15px] overflow-x-auto md:px-0 px-3">
            {pageNavs.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.route}
                  className={`rounded-md px-2 py-1 min-w-max
                    ${
                      item.route === window.location.pathname
                        ? "text-app-orange"
                        : "hover:text-app-orange"
                    }
                  `}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      {/* Routes */}
      <div className="max-w-screen-xl w-full mx-auto py-10 -mt-10 overflow-hidden">
        <div className="flex flex-col gap-10 px-3">
          <Routes>
            <Route
              path="/"
              element={
                <Overview
                  data={data}
                  existingTeams={data?.teams}
                  format={data?.format}
                  isMyTournament={isMyTournament}
                />
              }
            />
            <Route
              path={"/games"}
              element={<Games data={data} isMyTournament={isMyTournament} />}
            />
            <Route
              path="/standings"
              element={<Standings isMyTournament={isMyTournament} />}
            />
            <Route
              path="/leaderboard"
              element={<Leaderboard isMyTournament={isMyTournament} />}
            />
            <Route
              path="/gallery"
              element={<Gallery isMyTournament={isMyTournament} />}
            />
            <Route
              path="/createMatch"
              element={
                <CreateMatch
                  id={id}
                  threeByThree={data?.gameType && data?.gameType[0] === "3*3"}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}
