import React from "react";

export default function Stats() {
  const heading = [
    "#JRSY",
    "PLAYERS",
    "MIN",
    "FG",
    "2PTS",
    "FT",
    "O",
    "D",
    "TOT",
    "AS",
    "POINTS",
  ];
  const tableValue = [
    {
      jrsy: "#20",
      player: "JAMES",
      min: "20",
      fg: "20",
      twopts: "00",
      ft: "00",
      o: "00",
      d: "00",
      tot: "00",
      as: "00",
      points: "00",
    },
    {
      jrsy: "#20",
      player: "JAMES",
      min: "20",
      fg: "20",
      twopts: "00",
      ft: "00",
      o: "00",
      d: "00",
      tot: "00",
      as: "00",
      points: "00",
    },
    {
      jrsy: "#20",
      player: "JAMES",
      min: "20",
      fg: "20",
      twopts: "00",
      ft: "00",
      o: "00",
      d: "00",
      tot: "00",
      as: "00",
      points: "00",
    },
    {
      jrsy: "#20",
      player: "JAMES",
      min: "20",
      fg: "20",
      twopts: "00",
      ft: "00",
      o: "00",
      d: "00",
      tot: "00",
      as: "00",
      points: "00",
    },
  ];

  return (
    <>
      <div className="border border-border-gray rounded-xl overflow-x-auto">
        <table className="w-full text-center table-fixed">
          <thead className="bg-app-orange">
            {heading.map((item, index) => (
              <th
                key={index}
                className={`py-3 min-w-[100px] w-[100px]  ${
                  item === "MATCH" && ""
                }`}
              >
                {item}
              </th>
            ))}
          </thead>
          <tbody className="text-sm font-medium text-zinc-700">
            {/* All Values */}
            {tableValue.map((item, index) => (
              <tr key={index}>
                <td className="py-2 border-r last:border-none font-bold">
                  {item.jrsy}
                </td>
                <td className="py-2 border-r last:border-none">
                  {item.player}
                </td>
                <td className="py-2 border-r last:border-none">{item.min}</td>
                <td className="py-2 border-r last:border-none">{item.fg}</td>
                <td className="py-2 border-r last:border-none">
                  {item.twopts}
                </td>
                <td className="py-2 border-r last:border-none">{item.ft}</td>
                <td className="py-2 border-r last:border-none">{item.o}</td>
                <td className="py-2 border-r last:border-none">{item.d}</td>
                <td className="py-2 border-r last:border-none">{item.tot}</td>
                <td className="py-2 border-r last:border-none">{item.as}</td>
                <td className="py-2 border-r last:border-none">
                  {item.points}
                </td>
              </tr>
            ))}

            {/* Total */}
            <tr className="border-t">
              <td className="py-2 font-bold border-r" colSpan={2}>
                TOTAL
              </td>
              <td className="py-2 border-r">20</td>
              <td className="py-2 border-r">20</td>
              <td className="py-2 border-r">20</td>
              <td className="py-2 border-r">20</td>
              <td className="py-2 border-r">20</td>
              <td className="py-2 border-r">20</td>
              <td className="py-2 border-r">20</td>
              <td className="py-2 border-r">20</td>
              <td className="py-2">20</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
