import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import axiosInstance from "../axiosInstance";
import { ERROR, SUCCESS } from "../extras/constants";
import { completeLogin } from "../redux/actions/auth";
import { hideModal } from "../redux/actions/modal";
import { addToast } from "../redux/actions/toasts";

function VerifyOtpModal({ props }) {
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [timer, setTimer] = useState(30);
  const [mobileNumber, setmobileNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setmobileNumber(props.mobileNumber);
  }, []);

  const resendOtp = () => {
    setOTP("");
    const postData = {
      mobileNumber,
    };
    axiosInstance
      .post(`/player/login`, postData)
      .then((response) => {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Verification OTP Sent!",
          })
        );
        setDisabled(true);
        setTimer(30);
      })
      .catch(({ err }) => {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      });
  };

  const verifyOtp = (OTP) => {
    const postData = {
      OTP,
      mobileNumber,
    };

    setIsLoading(true);
    axiosInstance
      .post(`/player/otpVerify`, postData)
      .then((response) => {
        setIsLoading(false);

        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Account Verified!",
          })
        );
        dispatch(completeLogin(response.data.token));
        dispatch(hideModal());
      })
      .catch(({ response }) => {
        setIsLoading(false);

        try {
          switch (response.data.message) {
            case "OTP is Invalid":
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Invalid OTP! Try Again.",
                })
              );
              break;
            case 401:
              //   add a toast
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Invalid credentials!",
                })
              );
              break;
            default:
              // server error
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } catch (e) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Couldn't reach the server",
            })
          );
        }
      });
  };

  useEffect(() => {
    setDisabled(true);

    const timerCounter =
      timer > 0 &&
      setInterval(() => {
        setTimer((e) => e - 1);
      }, 1000);

    return () => {
      clearInterval(timerCounter);
    };
  }, [timer]);
  useEffect(() => {
    if (timer === 0) {
      setDisabled(false);
    }
  }, [timer]);

  return (
    <>
      <div className="w-full bg-white p-5 rounded-xl text-black ">
        <div className="text-xl mb-4 font-bold text-black">Verify OTP</div>
        <div className="text-xl mb-4  text-black">
          Enter the received verification code
        </div>

        <OtpInput
          value={OTP}
          onChange={setOTP}
          autoFocus
          numInputs={6}
          otpType="number"
          disabled={false}
          isInputNum
          separator={<span className="w-6 "></span>}
          secure
          //   className="text-black border-black bg-black  mx-auto h-6 w-7 "
          focusStyle="   border-[#C2E3F4]"
          containerStyle="appearance-none block w-full  border-2 rounded-full py-2 px-4 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] text-black   justify-evenly py-4"
          inputStyle=" !w-8 !h-8 rounded-md bg-[#C2E3F4] border-[#C2E3F4]"
          renderInput={(props, index) => {
            return (
              <input
                className=" !w-8 !h-8 rounded-md bg-[#C2E3F4] border-[#C2E3F4]"
                {...props}
                maxLength={1}
                key={index}
              />
            );
          }}
        />
        <button
          onClick={() => verifyOtp(OTP)}
          disabled={isLoading}
          className={`appearance-none rounded-full  block w-full mt-4 -mb-2 bg-black border-0 py-2 focus:outline-none text-sm font-semibold text-app-secondary ${
            isLoading ? "opacity-80 cursor-not-allowed" : ""
          }`}
        >
          Continue
        </button>
        <button
          disabled={disabled}
          onClick={resendOtp}
          className={`appearance-none rounded-full  block w-full mt-4 -mb-2 bg-black border-0 py-2 focus:outline-none text-sm font-semibold text-app-secondary  ${
            disabled ? "opacity-80 cursor-not-allowed" : ""
          } `}
        >
          {disabled ? <>Resend in {timer}</> : "Resend"}
        </button>
      </div>
    </>
  );
}

export default VerifyOtpModal;
