import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import {
  EDIT_MATCH_MODAL,
  ERROR,
  MATCH_STATUS,
  SUCCESS,
} from "../../extras/constants";
import { isInvalidMatchBeforeStart } from "../../extras/utils";
import useGetMatch from "../../hooks/api/useGetMatch";
import { addToast } from "../../redux/actions/toasts";
import { SHOW_MODAL } from "../../redux/actions/types";
// import EditCardShowPlayers from "./EditMatchBeforeStart/EditCardShowPlayers";
import axiosInstance from "../../axiosInstance";
import EditCardShowPlayers from "../../containers/EditMatchBeforeStart/EditCardShowPlayers";
// import { SUCCESS } from "../../extras/constants";

const EditMatch = ({ auth }) => {
  const { matchId, team } = useParams();
  const dispatch = useDispatch();
  const [isInValidMatch, setisInValidMatch] = useState(true);
  console.log("matchId => ",matchId,team);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { dataMatch: matchDetail } = useGetMatch({ id: matchId });
  const teamDetail = team === "A" ? matchDetail?.teamA : matchDetail?.teamB;
  const queryClient = useQueryClient();

  useEffect(() => {
    const isValid = isInvalidMatchBeforeStart(matchDetail);
    // console.log("invalid Match", isValid,matchDetail);
    setisInValidMatch(isValid);
  }, [matchDetail]);

  const startMatch = () => {
    setIsLoading(true);
    axiosInstance
      .post("/match/start", {
        matchId: matchId,
      })
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries(["myMatches", auth.accessToken]);
        queryClient.invalidateQueries("matchesList");
        navigate(`/matchDetail/${matchId}/update/score`);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Match Started Successfully!",
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);

        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable Start Match!",
          })
        );
      });
  };

  return (
    <div className="my-10 w-3/4 md:w-2/4">
      <Header title={matchDetail?.teamA?.name + " vs " + matchDetail?.teamB?.name} />

      <div className="rounded-xl m-auto mb-7 px-6 py-5 shadow-card min-h-card bg-white flex flex-col h-full justify-between ">
        <div className="flex justify-around mb-7 ">
          <button
            onClick={() => navigate(`/mymatches/edit/${matchId}/A`)}
            className={`px-2 outline-none w-full border-b-4  ${team === "A" ? " border-app-secondary" : "border-white "
              } font-semibold `}
          >
            {matchDetail?.teamA?.name || "..."}
          </button>

          <button
            onClick={() => navigate(`/mymatches/edit/${matchId}/B`)}
            className={`px-2 outline-none  w-full border-b-4  ${team === "B" ? " border-app-secondary" : "border-white"
              } font-semibold `}
          >
            {matchDetail?.teamB?.name || "..."}
          </button>
        </div>

        {!matchDetail?.threeByThree && (
          <>
            <div className="mt-3 w-full border-2 rounded-full py-2 px-4 text-sm flex justify-between items-center">
              <h1>Add Coach</h1>
              <Link
                to={`/mymatches/addcoach/${team}/${matchId}/${teamDetail?.name}`}
                state={{
                  teamDetail,
                }}
                className="px-4 py-1 rounded-xl  block bg-black text-md font-semibold text-white"
              >
                Add
              </Link>
            </div>

            <h1 className="font-semibold ml-4 mt-1">
              {team === "A" &&
                matchDetail?.teamA?.coach &&
                "Coach : " + matchDetail?.teamA?.coach}

              {team === "B" &&
                matchDetail?.teamB?.coach &&
                "Coach : " + matchDetail?.teamB?.coach}
              <br />
              {team === "A" &&
                matchDetail?.teamA?.assistant &&
                "Assistant : " + matchDetail?.teamA?.assistant}

              {team === "B" &&
                matchDetail?.teamB?.assistant &&
                "Assistant : " + matchDetail?.teamB?.assistant}
            </h1>
          </>
        )}
        <div className="mt-3 w-full border-2 rounded-full py-2 px-4 text-sm flex justify-between items-center">
          <h1>Add Player</h1>
          <Link
            to={`/mymatches/addplayer/${team}/${matchId}/${team === "A" ? matchDetail?.teamA?.name : matchDetail?.teamB?.name
              }`}
            className="px-4 py-1 rounded-xl  block bg-black text-md font-semibold text-white"
          >
            Add
          </Link>
        </div>

        <div>
          <EditCardShowPlayers
            team={team === "A" ? matchDetail?.teamA : matchDetail?.teamB}
          />
        </div>
        <button
          className="btn-primary mt-6 "
          disabled={isInValidMatch || isLoading}
          onClick={() => {
            window.scrollTo(0, 0);
            matchDetail?.status === MATCH_STATUS.UPCOMING
              ? navigate(`/mymatches/selectPlayer/${matchDetail._id}/A`)
              : navigate(`/matchDetail/${matchDetail._id}/update/score`);
            // console.log(matchDetail);
          }}
        >
          {matchDetail?.status === MATCH_STATUS.LIVE
            ? "UPDATE GAME"
            : "CONTINUE"}
        </button>
        <button
          onClick={() => {
            // console.log(matchDetail);
            dispatch({
              type: SHOW_MODAL,
              payload: {
                modalType: EDIT_MATCH_MODAL,
                modalProps: {
                  matchDetail,
                },
              },
            });
          }}
          className="btn-primary text-lg text-center mt-4"
        >
          Edit Match
        </button>
      </div>
    </div>
  );
};

export default EditMatch;