import { useFormik } from "formik";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";

import axiosInstance from "../../axiosInstance";
import { ERROR, LOGIN_MODAL, SUCCESS } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";
import { addToast } from "../../redux/actions/toasts";

import Input from "../../components/Input/Input";

export default function CreateTeam() {
  const [isLoading, setIsLoading] = useState(false);
  const [embedLink, setEmbedLink] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    coach: "",
    assistant: "",
    color: "",
  };

  const handleSubmit = async (values) => {
    setIsBtnLoading(true);
    try {
      const res = await axiosInstance.post(`/team/create`, values);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Team Created Successfully!",
        })
      );
      formik.resetForm();
      if (res.status === 201) {
        navigate(`/myteam/edit/${res?.data?.data?._id}`);
      } else {
        navigate(`/myteam/edit/${res?.data?.data?._id}`);
      }
    } catch (err) {
      if (err?.response?.status === 409) {
        dispatch(
          addToast({
            kindL: ERROR,
            msg: "Team Found. Please use a different Team Name!",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable to Create Team",
          })
        );
      }
    }
    setIsBtnLoading(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      coach: Yup.string().required("Required"),
      assistant: Yup.string().required("Required"),
      color: Yup.string().required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div className="max-w-screen-xl w-full mx-auto p-4 font-mont mt-4">
      <div className="flex flex-col gap-7">
        <h1 className="text-xl font-bold tracking-wide">CREATE A TEAM</h1>

        {auth.accessToken ? (
          <>
            <div className="justify-center">
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-1"
              >
                <div className="flex flex-col gap-3">
                  <Input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    placeholder="TEAM NAME"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={
                      formik.touchedvalues?.name && formik.errorsvalues?.name
                    }
                    error={formik.errorsvalues?.name}
                  />
                  <Input
                    type="text"
                    name="coach"
                    value={formik.values.coach}
                    placeholder="TEAM COACH"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={formik.touched.coach && formik.errors.coach}
                    error={formik.errors.coach}
                  />
                  <Input
                    type="text"
                    name="assistant"
                    value={formik.values.assistant}
                    placeholder="ASSISTANT COACH"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={
                      formik.touched.assistant && formik.errors.assistant
                    }
                    error={formik.errors.assistant}
                  />
                  <Input
                    name="color"
                    value={formik.values.color}
                    placeholder="Team Color ( HEX CODE )"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={formik.touched.color && formik.errors.color}
                    error={formik.errors.color}
                  />
                </div>
                <button
                  type="submit"
                  className="mt-5 w-full px-4 py-3 text-sm text-zinc-700 font-bold bg-app-orange rounded-xl text-center"
                  disabled={isBtnLoading}
                >
                  CREATE TEAM
                </button>
              </form>
            </div>
          </>
        ) : (
          <>
            <p className="text-center mb-8">
              Please Login before creating/editing a Team
            </p>
            <button
              onClick={() => showModal({ modalType: LOGIN_MODAL })}
              className="m-auto block py-2 px-4 font-medium rounded bg-app-secondary text-white"
            >
              Log In
            </button>
          </>
        )}
      </div>
    </div>
  );
}
