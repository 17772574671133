import React from "react";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

const headings = [
  "Total Points",
  "2 Points",
  "3 Points",
  "Free Throws",
  "Free Throws %",
];

const Range = ({ teamAScore, teamBScore, heading }) => {
  const maxScore = Math.max(teamAScore, teamBScore);
  const percentageAScore = (teamAScore * maxScore) / 100;
  const percentageBScore = (teamBScore * maxScore) / 100;
  return (
    <div className="flex items-center justify-center w-full flex-col gap-2 xs:gap-4 py-2 xs:p-4">
      <div className="flex items-center justify-center gap-3 xs:gap-6 w-full ">
        <p className="font-medium text-xs">{teamAScore}</p>
        <div className="w-full flex items-center justify-start relative overflow-hidden">
          <div className="flex items-center justify-center bg-[#e4e4e4] w-[18rem] sm:w-[20rem] md:w-[22rem] lg:w-[24rem] h-[2rem]"></div>
          <div
            style={{ transform: `translateX(${-percentageAScore}%)` }}
            className="flex items-center justify-center absolute bg-secondaryA right-[0px] w-[18rem] sm:w-[20rem] md:w-[22rem] lg:w-[24rem] h-[2rem]"
          ></div>
        </div>
        <p className="font-medium text-xs w-[5rem] sm:w-[6rem] md:w-[7rem] lg:w-[8rem] text-center">
          {heading}
        </p>

        <div className="w-full flex items-center justify-start relative overflow-hidden">
          <div className="flex items-center justify-center bg-[#e4e4e4] w-[18rem] sm:w-[20rem] md:w-[22rem] lg:w-[24rem] h-[2rem]"></div>
          <div
            style={{ transform: `translateX(-${percentageBScore}%)` }}
            className="flex items-center justify-center absolute bg-secondaryB w-[18rem] sm:w-[20rem] md:w-[22rem] lg:w-[24rem] h-[2rem] left-[0px]"
          ></div>
        </div>
        <p className="font-medium text-xs">{teamBScore}</p>
      </div>
    </div>
  );
};

const TeamComparison = ({
  teamAScore = 60,
  teamBScore = 40,
  teamAName,
  teamBName,
  eventsObject,
}) => {
  return (
    <div className="w-full h-full col-span-4 flex flex-col font-mont items-center justify-center px-6 py-4 rounded-[12px] border border-[#E6E6E6]">
      <div className="w-full flex items-center justify-start border-b border-[#e6e6e6] p-2">
        <p className="font-bold uppercase text-base">Team Comparison</p>
      </div>
      <div className="w-full flex items-center justify-around gap-4 py-4 border-b border-[#e6e6e6]">
        <div className="flex items-center justify-center gap-6">
          <div className="flex items-center justify-center relative">
            <div className="h-[8px] w-[3rem] sm:w-[4rem] md:w-[5rem] lg:w-[6rem] bg-secondaryA"></div>
            <ProfilePhoto
              name={teamAName ?? "Team A"}
              color="black"
              restClass="w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 absolute -right-2 rounded-full"
            />
          </div>
          <p className="font-medium text-xs">{teamAName}</p>
        </div>
        <div className="flex items-center justify-center gap-6">
          <div className="flex items-center justify-center relative">
            <div className="h-[8px] w-[3rem] sm:w-[4rem] md:w-[5rem] lg:w-[6rem] bg-secondaryB"></div>
            <ProfilePhoto
              name={teamBName ?? "Team B"}
              color="black"
              restClass="w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 absolute -right-2 rounded-full"
            />
          </div>
          <p className="font-medium text-xs">{teamBName}</p>
        </div>
      </div>
      {headings.map((heading, index) => (
        <div key={index} className="flex w-full">
          <Range
            heading={heading}
            teamAScore={teamAScore}
            teamBScore={teamBScore}
          />
        </div>
      ))}
    </div>
  );
};

export default TeamComparison;
