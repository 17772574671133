import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { LOGIN_MODAL, SUCCESS } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";
import { addToast } from "../../redux/actions/toasts";

const CreateTournament = ({ auth, showModal, addToast }) => {
  const NewTournament = Yup.object().shape({
    title: Yup.string().required("Required"),
    numberOfMatches: Yup.string().required("Required"),
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
    cover: Yup.mixed().test("cover", "Please upload a Cover Image", (value) => {
      if (!value) {
        return false;
      }
      return value instanceof File;
    }),
    logo: Yup.mixed().test("logo", "Please upload a logo", (value) => {
      if (!value) {
        return false;
      }
      return value instanceof File;
    }),
    gameType: Yup.string().test(
      "gameType",
      "Please select a game type",
      (value) => value === "3*3" || value === "5*5"
    ),
    format: Yup.string().test(
      "format",
      "Please select a format type",
      (value) => value === "knockout" || value === "league" || value === "group"
    ),
  });

  const initialValues = {
    title: "",
    numberOfMatches: "",
    startDate: new Date(),
    socialMediaLink: "",
    eventRegisterationLink: "",
    endDate: new Date(),
    logo: "",
    cover: "",
    gameType: "",
    format: "",
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [venue, setVenue] = React.useState([]);
  const [groups, setGroups] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("numberOfMatches", values.numberOfMatches);
    venue.length > 0 &&
      venue?.forEach((v) => {
        formData.append("venues", v);
      });
    formData.append("startDate", new Date(values.startDate).getTime());
    formData.append("endDate", new Date(values.endDate).getTime());
    formData.append("socialMediaLink", values.socialMediaLink);
    formData.append("eventRegistrationLink", values.eventRegisterationLink);
    formData.append("logo", values.logo);
    formData.append("cover", values.cover);
    formData.append("gameType", values.gameType);
    if (values.format === "group") {
      formData.append("numOfGroups", groups);
    }
    formData.append("format", values.format);

    try {
      const res = await axiosInstance.post("/tournament/create", formData);
      if (res.status === 201 || res.status === 200) {
        dispatch(
          addToast({
            msg: "Tournament created successfully",
            kind: SUCCESS,
          })
        );
        console.log(res, "RES CREATE TOURNAMENT");
        navigate(`/tournaments/basketball/${res?.data?.data?.tournament?._id}`);
        dispatch(showModal(false));
      }
    } catch (error) {
      console.log(error, "ERR CREATE TOURNAMENT");
    } finally {
      setLoading(false);
    }
  };

  if (!auth.isLoggedIn) {
    return (
      <div className="flex flex-col gap-2 py-10 items-center space-x-3">
        <p className="text-center font-semibold text-xl">
          Please Login to see Player Profiles
        </p>
        <button
          onClick={() =>
            showModal({
              modalType: LOGIN_MODAL,
            })
          }
          className="px-6 py-3 uppercase font-medium text-white hover:bg-app-secondary bg-black rounded-[15px] text-xs font-opsans transition duration-300"
        >
          Sign In
        </button>
      </div>
    );
  }

  return (
    <div className="w-full py-10 flex flex-col gap-10">
      <div className="max-w-screen-xl w-full mx-auto flex flex-col gap-5 md:px-2 px-3">
        <h1 className="text-xl font-bold tracking-wide">CREATE A TOURNAMENT</h1>
      </div>
      <div className="max-w-screen-xl w-full mx-auto flex flex-col gap-5 md:px-0 px-3">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={NewTournament}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            setFieldValue,
            touched,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <>
                <form
                  encType="multipart/form-data"
                  className="flex flex-col gap-5"
                  disabled={loading}
                >
                  <div className="relative">
                    {/* Cover */}
                    <div>
                      <button
                        type="button"
                        disabled={loading}
                        onClick={(e) => {
                          e.stopPropagation();
                          document.getElementById("fileCover").click();
                        }}
                        className="w-full h-52 rounded-xl bg-zinc-300 flex justify-center items-center p-3 relative overflow-hidden"
                      >
                        {values.cover ? (
                          <div>
                            <ClearIcon
                              fontSize="small"
                              color="red"
                              onClick={(e) => {
                                e.stopPropagation();
                                setFieldValue("cover", "");
                              }}
                              className="cursor-pointer z-10 absolute top-2 right-2"
                            />

                            <img
                              src={URL.createObjectURL(values.cover)}
                              alt="Selected Cover"
                              className="w-full h-full object-cover absolute rounded-xl top-0 left-0"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col gap-5 items-center">
                            <div className="bg-zinc-500 p-3 flex justify-center items-center text-zinc-200 text-6xl w-12 h-12">
                              +
                            </div>
                            <p className="text-zinc-600 font-semibold text-[13px]">
                              ADD TOURNAMENT COVER PHOTO
                            </p>
                            <input
                              type="file"
                              id="fileCover"
                              value={values.cover}
                              name="cover"
                              onChange={(event) => {
                                const files = event.target.files[0];
                                setFieldValue("cover", files);
                              }}
                              className="hidden"
                            />
                          </div>
                        )}
                      </button>
                      {errors.logo && touched.logo && (
                        <div className="text-red-500">{errors.logo}</div>
                      )}
                    </div>

                    {/* Logo */}
                    <div>
                      <button
                        type="button"
                        disabled={loading}
                        onClick={(e) => {
                          e.stopPropagation();
                          document.getElementById("fileLogo").click();
                        }}
                        className="border-2 border-white min-w-44 w-[25%] h-44 md:h-56 rounded-xl bg-zinc-300 flex justify-center items-center p-3 absolute top-[40%] left-8 md:left-[10%] z-20"
                      >
                        {values.logo ? (
                          <div>
                            <ClearIcon
                              fontSize="small"
                              color="red"
                              onClick={(e) => {
                                e.stopPropagation();
                                setFieldValue("logo", "");
                              }}
                              className="cursor-pointer z-10 absolute top-2 right-2"
                            />
                            <img
                              src={URL.createObjectURL(values.logo)}
                              alt="Selected Logo"
                              className="w-full h-full object-cover rounded-xl absolute top-0 left-0"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col gap-5 items-center">
                            <div className="bg-zinc-500 p-3 flex justify-center items-center text-zinc-200 text-6xl w-12 h-12">
                              +
                            </div>
                            <p className="text-zinc-600 font-semibold text-[13px] text-center">
                              ADD TOURNAMENT LOGO
                            </p>
                            <input
                              type="file"
                              id="fileLogo"
                              value={values.logo}
                              name="logo"
                              onChange={(event) => {
                                const files = event.target.files[0];
                                setFieldValue("logo", files);
                              }}
                              className="hidden"
                            />
                          </div>
                        )}
                      </button>
                      {errors.cover && touched.cover && (
                        <div className="text-red-500">{errors.cover}</div>
                      )}
                    </div>
                  </div>

                  {/* Divider */}
                  <div className="py-16"></div>

                  <div>
                    <input
                      type="text"
                      name="title"
                      value={values.title}
                      onChange={handleChange("title")}
                      className="w-full border-2 rounded-xl py-3 px-4 leading-tight focus:outline-none text-sm placeholder:text-zinc-400 placeholder:font-medium"
                      placeholder="ENTER TOURNAMENT NAME"
                    />
                    {touched.title && errors.title && (
                      <p className="text-red-500 ml-4">{errors.title}</p>
                    )}
                  </div>

                  <label>
                    <span className="text-[#848484]">Venue</span>
                    <Autocomplete
                      multiple
                      onChange={(e, n) => {
                        // console.log(n)
                        setVenue(n);
                      }}
                      autoComplete={false}
                      value={venue}
                      options={[]}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </label>

                  <div>
                    <div className="flex gap-5 items-center border-2 rounded-xl px-4 py-2">
                      <label className="text-zinc-400 font-medium text-sm">
                        CHOOSE GAME TYPE -
                      </label>
                      <div className=" text-sm flex items-center gap-3">
                        <button
                          type="button"
                          name="gameType"
                          value="3*3"
                          onClick={() => {
                            setFieldValue("gameType", "3*3");
                          }}
                          className={`rounded-full text-center px-4 py-[2px] font-semibold
                          ${
                            values.gameType === "3*3"
                              ? "bg-app-orange text-zinc-700"
                              : "bg-white border"
                          }`}
                        >
                          3*3
                        </button>
                        <button
                          type="button"
                          name="gameType"
                          value="5*5"
                          onClick={() => {
                            setFieldValue("gameType", "5*5");
                          }}
                          className={`rounded-full text-center px-4 py-[2px] font-semibold
                          ${
                            values.gameType === "5*5"
                              ? "bg-app-orange text-zinc-700"
                              : "bg-white border"
                          }`}
                        >
                          5*5
                        </button>
                      </div>
                    </div>
                    {errors.gameType && touched.gameType ? (
                      <div className="text-red-500">{errors.gameType}</div>
                    ) : null}
                  </div>
                  <div>
                    <input
                      type="number"
                      name="numberOfMatches"
                      value={values.numberOfMatches}
                      onChange={handleChange("numberOfMatches")}
                      className="w-full border-2 rounded-xl py-3 px-4 leading-tight focus:outline-none text-sm placeholder:text-zinc-400 placeholder:font-medium"
                      placeholder="ENTER NUMBER OF MATCHES"
                    />
                    {touched.numberOfMatches && errors.numberOfMatches && (
                      <p className="text-red-500 ml-4">
                        {errors.numberOfMatches}
                      </p>
                    )}
                  </div>

                  {/* Dates */}
                  <div className="flex gap-5 items-center flex-wrap">
                    <div>
                      <div className="flex gap-5 items-center border-2 rounded-xl px-4 py-3">
                        <label
                          htmlFor="startDate"
                          className="text-zinc-400 font-medium text-sm"
                        >
                          ENTER START DATE -
                        </label>
                        <input
                          type="date"
                          name="startDate"
                          id="startDate"
                          onChange={handleChange("startDate")}
                          className=" leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
                          placeholder="00"
                        />
                      </div>
                      {touched.startDate && errors.startDate && (
                        <p className="text-red-500 ml-4">{errors.startDate}</p>
                      )}
                    </div>

                    <div className="text-zinc-500 font-medium text-sm">TO</div>

                    <div>
                      <div className="flex gap-5 items-center border-2 rounded-xl px-4 py-3">
                        <label
                          htmlFor="endDate"
                          className="text-zinc-400 font-medium text-sm"
                        >
                          ENTER END DATE -
                        </label>
                        <input
                          type="date"
                          name="endDate"
                          id="endDate"
                          onChange={handleChange("endDate")}
                          className="leading-tight focus:outline-none text-sm  text-zinc-500"
                          placeholder="00"
                        />
                      </div>
                      {touched.endDate && errors.endDate && (
                        <p className="text-red-500 ml-4">{errors.endDate}</p>
                      )}
                    </div>
                  </div>

                  <div>
                    <input
                      type="text"
                      name="eventRegisterationLink"
                      value={values.eventRegisterationLink}
                      onChange={handleChange("eventRegisterationLink")}
                      className="w-full border-2 rounded-xl py-3 px-4 leading-tight focus:outline-none text-sm placeholder:text-zinc-400 placeholder:font-medium"
                      placeholder="ENTER REGISTRATION LINK"
                    />
                    {touched.eventRegisterationLink &&
                      errors.eventRegisterationLink && (
                        <p className="text-red-500 ml-4">
                          {errors.eventRegisterationLink}
                        </p>
                      )}
                  </div>
                  <div>
                    <input
                      type="text"
                      name="socialMediaLink"
                      value={values.socialMediaLink}
                      onChange={handleChange("socialMediaLink")}
                      className="w-full border-2 rounded-xl py-3 px-4 leading-tight focus:outline-none text-sm placeholder:text-zinc-400 placeholder:font-medium"
                      placeholder="ENTER SOCIAL MEDIA LINK"
                    />
                    {touched.socialMediaLink && errors.socialMediaLink && (
                      <p className="text-red-500 ml-4">
                        {errors.socialMediaLink}
                      </p>
                    )}
                  </div>

                  <div>
                    <div className="flex gap-5 items-center border-2 rounded-xl px-4 py-2">
                      <label className="text-zinc-400 font-medium text-sm">
                        CHOOSE GAME FORMAT -
                      </label>
                      <div className=" text-sm flex flex-wrap items-center gap-3">
                        <button
                          type="button"
                          name="format"
                          value="knockout"
                          className={`rounded-full text-center px-4 py-[2px] font-semibold
                          ${
                            values.format === "knockout"
                              ? "bg-app-orange text-zinc-700"
                              : "bg-white border"
                          }`}
                          onClick={() => {
                            setFieldValue("format", "knockout");
                          }}
                        >
                          Knockout
                        </button>
                        <button
                          type="button"
                          name="format"
                          value="group"
                          className={`rounded-full text-center px-4 py-[2px] font-semibold
                          ${
                            values.format === "group"
                              ? "bg-app-orange text-zinc-700"
                              : "bg-white border"
                          }`}
                          onClick={() => {
                            setFieldValue("format", "group");
                          }}
                        >
                          Group
                        </button>
                        <button
                          type="button"
                          name="format"
                          value="League"
                          className={`rounded-full text-center px-4 py-[2px] font-semibold
                          ${
                            values.format === "league"
                              ? "bg-app-orange text-zinc-700"
                              : "bg-white border"
                          }`}
                          onClick={() => {
                            setFieldValue("format", "league");
                          }}
                        >
                          League
                        </button>
                      </div>
                    </div>
                    {errors.format && touched.format ? (
                      <div className="text-red-500">{errors.format}</div>
                    ) : null}
                  </div>
                  {values.format === "group" ? (
                    <>
                      <div>
                        <input
                          type="number"
                          name="numOfGroups"
                          value={groups}
                          onChange={(e) => setGroups(e.target.value)}
                          className="w-full border-2 rounded-xl py-3 px-4 leading-tight focus:outline-none text-sm placeholder:text-zinc-400 placeholder:font-medium"
                          placeholder="ENTER NUMBER OF GROUPS"
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="flex justify-center items-center w-full">
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      disabled={loading}
                      className="w-full px-4 py-3 text-sm text-zinc-700 font-bold bg-app-orange rounded-xl text-center"
                    >
                      {loading ? "CREATING..." : "CREATE TOURNAMENT"}
                    </button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
    addToast: (payload) => dispatch(addToast(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTournament);
