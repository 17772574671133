import React from "react";
import player from "../../assets/player.svg";
import aboutVec from "../../assets/aboutVec.svg";

const AboutSection = () => {
  return (
    <section className="flex items-center justify-center w-full min-h-[100dvh] h-full bg-black rounded-2xl">
      <div className="flex items-center justify-center w-full max-w-screen-xl mx-auto sm:py-4">
        <div className="flex items-center justify-center w-full py-8 px-2">
          <div className="flex flex-col items-start justify-start gap-4 w-full relative">
            <div className="flex items-start justify-start w-full gap-8 flex-col z-[1]">
              <h1 className="text-4xl sm:text-5xl font-benzin text-white">
                About us
              </h1>
              <div className="h-[15px] w-[15rem] bg-[#FFA808]"></div>
            </div>
            <div className="flex items-center w-full justify-center z-[1]">
              <div className="flex items-center justify-center lg:absolute left-36">
                <h1 className="font-bold text-[#878787] text-3xl xs:text-4xl sm:text-6xl max-w-xl">
                  Keep a score of
                  <br />
                  your 3x3 or 5x5 <br />
                  basketball <br />
                  matches with Score4All.
                </h1>
              </div>
              <div className="hidden lg:flex items-center justify-end w-full">
                <div className="flex items-center justify-center pr-32">
                  <img src={player} alt="" className="h-full w-full" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center text-white pt-4 sm:pl-12 md:pl-36 z-[1]">
              <p className="flex font-bold text-xl xs:text-2xl sm:text-4xl font-mont">
                Much more to come soon...
              </p>
            </div>

            <div className="items-center flex  justify-center h-full absolute right-0 py-6">
              <img src={aboutVec} alt="aboutVec" className="h-full w-full" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
