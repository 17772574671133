import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import BoxScoreCard from "../../components/matches/BoxScoreCard";
import GameEvolutionComparison from "../../components/matches/GameEvolutionComparison";
import GameFlow from "../../components/matches/GameFlow";
import GameLeader from "../../components/matches/GameLeader";
import LastFiveMatches from "../../components/matches/LastFiveMatches";
import PlayByPlay from "../../components/matches/PlayByPlay";
import ShootPercent from "../../components/matches/ShootPercent";
import SingleMatchesNav from "../../components/matches/SingleMatchesNav";
import TeamComparison from "../../components/matches/TeamComparison";
import TeamStatTable from "../../components/matches/TeamStatTable";
import TopPlayer from "../../components/matches/TopPlayer";
import MatchesTeamCard from "./MatchesTeamCard";

const SingleMatchesSection = ({ data, isLoading }) => {
  const { tabs } = useParams();
  let matchData = data;
  let matchId = matchData?._id;

  const [totalScore, setTotalScore] = useState({
    teamA: 0,
    teamB: 0,
  });

  const getQuarterScores = async () => {
    const { data } = await axiosInstance.get(
      `/match/getQuarterScore/${matchId}`
    );
    const { teamA, teamB } = data;
    let teamAScore = Object.values(teamA);
    teamAScore = teamAScore.reduce((acc, score) => acc + score, 0);
    // console.log(teamAScore);
    let teamBScore = Object.values(teamB);
    teamBScore = teamBScore.reduce((acc, score) => acc + score, 0);
    // console.log(teamAScore, teamBScore);
    setTotalScore({
      teamA: teamAScore,
      teamB: teamBScore,
    });
    return data;
  };
  const { data: quarterScores, error } = useQuery(
    ["getMatch", "getQuarter", matchId],
    getQuarterScores
  );

  return (
    <section className="w-full min-h-[100dvh] flex items-start justify-start">
      <div className="flex items-center justify-center w-full flex-col">
        <div className="flex items-center justify-center flex-col gap-18 w-full pt-6">
          <MatchesTeamCard
            data={matchData}
            quarterScores={quarterScores}
            totalScore={totalScore}
          />
        </div>
        <SingleMatchesNav hidePreview={true} />
        {tabs === "overview" ? (
          <div className="grid max-w-screen-xl mx-auto py-6 px-4 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 items-center justify-center w-full gap-12">
            <div className="grid w-full items-center justify-center md:grid-cols-2 grid-cols-1 col-span-4 gap-4">
              <GameLeader data={matchData} />
              <GameFlow
                data={quarterScores}
                teamAName={matchData?.teamA?.name}
                teamBName={matchData?.teamB?.name}
              />
            </div>
            {/* <OverallStats /> */}
          </div>
        ) : tabs === ("preview" && matchData?.status !== "finished") ? (
          <div className="grid max-w-screen-xl mx-auto py-6 px-4 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 items-center justify-center w-full gap-12">
            <TeamComparison
              teamAScore={totalScore?.teamA}
              teamBScore={totalScore?.teamB}
              teamAName={matchData?.teamA?.name}
              teamBName={matchData?.teamB?.name}
              eventsObject={matchData?.events}
            />
            <ShootPercent data={matchData} />
            <TopPlayer />
            <LastFiveMatches />
          </div>
        ) : tabs === "playbyplay" ? (
          <div className="grid max-w-screen-xl mx-auto py-6 px-4 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 items-center justify-center w-full gap-12">
            <PlayByPlay data={matchData} totalScore={totalScore} />
          </div>
        ) : tabs === "teamcomparison" ? (
          <div className="flex flex-col w-full max-w-screen-xl py-6 px-4">
            <div className="grid mx-auto py-6 px-4 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 items-center justify-center w-full gap-12">
              <ShootPercent data={matchData} />
            </div>
            <GameEvolutionComparison
              data={quarterScores}
              teamAName={matchData?.teamA?.name}
              teamBName={matchData?.teamB?.name}
            />
          </div>
        ) : tabs === "boxscore" ? (
          <div className="flex flex-col py-6 items-center justify-center w-full gap-12">
            <TeamStatTable matchData={matchData} />
            <BoxScoreCard data={matchData} />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default SingleMatchesSection;
