import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import MatchesTeamCard from "../MatchesPage/MatchesTeamCard";

export default function MyMatches() {
  const heading = [
    "MATCH",
    "PTS/40",
    "R/40",
    "A/40",
    "TS%",
    "AST",
    "TO",
    "USG",
    "REBR",
    "RPM",
  ];
  const tableValue = [
    {
      match: "COMING SOON",
      pts: "--",
      r: "--",
      a: "--",
      ts: "--",
      ast: "--",
      to: "--",
      usg: "--",
      rebr: "--",
      rpm: "--",
    },
  ];

  const [matches, setMatches] = useState({});
  const auth = useSelector((state) => state.auth);

  const fetchMyMatches = async () => {
    const res = await axiosInstance.get(`/match/getMyMatches`);
    let data = [
      ...res?.data?.live,
      ...res?.data?.upcoming,
      ...res?.data?.finished,
    ];

    data.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return data;
  };

  const {
    data: apiResponse,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(["myParticipated", auth.accessToken], fetchMyMatches);

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          {apiResponse?.map((item, index) => (
            <MatchesTeamCard key={index} data={item} isMyMatch={true} />
          ))}
        </div>
      </div>
    </>
  );
}

{
  /* <div className="border border-border-gray rounded-xl overflow-x-auto">
<table className="w-full text-center table-fixed">
  <thead className="bg-app-orange">
    {heading.map((item, index) => (
      <th
        key={index}
        className={`py-3 min-w-[100px] w-[100px]  ${
          item === "MATCH" && ""
        }`}
      >
        {item}
      </th>
    ))}
  </thead>
  <tbody className="text-sm font-medium text-zinc-700">
    {/* All Values */
}
//     {tableValue.map((item, index) => (
//       <tr key={index}>
//         <td className="py-2 border-r last:border-none">
//           {item.match}
//         </td>
//         <td className="py-2 border-r last:border-none">{item.pts}</td>
//         <td className="py-2 border-r last:border-none">{item.r}</td>
//         <td className="py-2 border-r last:border-none">{item.a}</td>
//         <td className="py-2 border-r last:border-none">{item.ts}</td>
//         <td className="py-2 border-r last:border-none">{item.ast}</td>
//         <td className="py-2 border-r last:border-none">{item.to}</td>
//         <td className="py-2 border-r last:border-none">{item.usg}</td>
//         <td className="py-2 border-r last:border-none">
//           {item.rebr}
//         </td>
//         <td className="py-2 border-r last:border-none">{item.rpm}</td>
//       </tr>
//     ))}
//   </tbody>
// </table>
// </div>
// <div className="flex justify-end font-medium text-sm">
// <button>VIEW ALL</button>
// </div> */}
