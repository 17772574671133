import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";

function useGetRecentMatch({ date, status }) {
  const getMatch = async ({ queryKey }) => {
    const [_key, { status, date }] = queryKey;

    const { data } = await axiosInstance.get(`/match/getRecentMatches`, {
      params: {
        status,
        date,
      },
    });
    return data.ag;
  };

  const {
    data: dataMatch,
    error: errorMatch,
    isLoading: isLoadingMatch,
    isSuccess: isSuccessMatch,
  } = useQuery(["getRecentMatches", { date, status }], getMatch);

  return {
    dataMatch,
    errorMatch,
    isSuccessMatch,
    isLoadingMatch,
  };
}

export default useGetRecentMatch;
