import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import MatchHeader from "../Header";
import EditTeamShowPlayers from "./EditTeamShowPlayers";

// import { SUCCESS } from "../../extras/constants";

const EditTeamPlayers = () => {
  const auth = useSelector((state) => state.auth);
  const { teamId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchTeamDetails = async () => {
    const res = await axiosInstance.get(`/team/detail/${teamId}`);

    return res.data;
  };

  const {
    data: apiResponse,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(["getTeamDetail", auth.accessToken], fetchTeamDetails);

  return (
    <div className="my-10 w-3/4 md:w-2/4">
      <MatchHeader title={"Add Players to your Team"} />

      <div className="rounded-xl m-auto mb-7 px-6 py-5 shadow-card min-h-card bg-white flex flex-col h-full justify-between ">
        <div className="mt-3 w-full border-2 rounded-full py-2 px-4 text-sm flex justify-between items-center">
          <h1>Add Player</h1>
          <Link
            to={`/myteam/addplayer/${teamId}`}
            className="px-4 py-1 rounded-xl  block bg-black text-md font-semibold text-white"
          >
            Add
          </Link>
        </div>

        <div>
          <EditTeamShowPlayers team={apiResponse?.data} />
        </div>
        <button
          className="btn-primary mt-6 "
          disabled={isLoading}
          onClick={() => {
            navigate("/");
          }}
        >
          DONE
        </button>
      </div>
    </div>
  );
};

export default EditTeamPlayers;
