import React from "react";

const Input = ({
  name,
  id,
  value,
  placeholder,
  handleChange,
  handleBlur,
  isError,
  error,
  label,
  ...otherProps
}) => {
  return (
    <div className="w-full">
      <label className="text-black font-light text-sm">{label}</label>
      <input
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        className="w-full border-2 rounded-xl py-3 px-4 leading-tight focus:outline-none text-sm placeholder:uppercase placeholder:text-zinc-400 placeholder:font-medium"
        {...otherProps}
      />
      {isError && <p className="text-red-500 ml-4">{error}</p>}
    </div>
  );
};

export default Input;
